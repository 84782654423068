export const API_STATUS = {
  SUCCESS: 'API Succeeded',
  FAIL: 'API Failed'
};

export const EVENTS = {
  API_CALL: 'API Call',
  PAGE_SIZE_CHANGE: 'Page Size Changed',
  SORTING_COLUMN: 'Sorting Column',
  ATTESTATION: 'Attestation',
  BUTTON_CLICK: 'Button clicked',
  LINK_CLICK: 'Link clicked',
  CARD_CLICK: 'Card clicked',
  USER_LOGIN: 'User Login',
  USER_ACTION: 'User Action'
};

export const ATTESTATION_TYPES = {
  ENVIRONMENT: 'Environment Attestation',
  APP: 'App Attestation'
};

export const PAGES = {
  CONTAINER: 'Container with left menu',
  HOME: 'Home page',
  REQUEST_STATUS: 'Request Status',
  ENVIRONMENT_REQUEST_STATUS: 'Environment Request Status',
  MAILBOXSSS_REQUEST_STATUS: 'Mailbox SSS Request Status',
  ENVIRONMENT_NEW: 'Create Environment',
  ENVIRONMENT_LIST: 'View Environments',
  ENVIRONMENT_ATTESTATION: 'Environment Attestation Panel',
  ENVIRONMENT_EDIT_METADATA: 'Environment Edit Metadata Panel',
  ENVIRONMENT_VIOLATIONS: 'Environment Violations',
  SHAREPOINT_LIST: 'Sharepoint Sites List',
  SHAREPOINT_VIOLATIONS: 'Sharepoint Sites Violations',
  PBI_WORKSPACES: 'PBI workspaces',
  MAILBOXSSS_REQUEST: 'Create Mailbox SSS Request',
  MAILBOXSSS_GA_APPROVAL: 'Mailbox SSS GA Approval'
};
