import { IColumn } from '@fluentui/react';

export const PBI_CONST = {
    ApiStatus: {
      InViolation: 'In Violation',
      Deleted: 'System Deleted',
      Quarantined: 'Quarantined',
      RemoveFromQuarantine: 'Compliant',
      Compliant: 'Compliant'
    },
    StatusStyle: {
      InViolation: 'markNonCompliant',
      Deleted: 'markDeleted',
      Quarantined: 'markQuarantined',
      RemoveFromQuarantine: 'markCompliant',
      Compliant: 'markCompliant'
    },
    ActionLabel: {
      InViolation: 'Remediate',
      Deleted: 'Restore',
      Quarantined: 'Unquarantine',
      RemoveFromQuarantine: 'Unquarantine',
      Compliant: 'Compliant',
      EditMetaData: 'Edit Metadata'
    }
  };
  
  export const PBI_API_STATE = {
    IN_VIOLATION: 'InViolation',
    DELETED: 'Deleted',
    QUARANTINED: 'Quarantined',
    REMOVE_FROM_QUARANTINE: 'RemoveFromQuarantine',
    COMPLIANT: 'Compliant'
};
  
export const COLUMNS: IColumn[] = [
  {
    key: 'displayName',
    name: '  Name',
    fieldName: 'displayName',
    maxWidth: 350,
    minWidth: 350,
    isMultiline: true
  },
  {
    key: 'created',
    name: 'Created Date(UTC)',
    fieldName: 'created',
    maxWidth: 150,
    minWidth: 150
  },
  {
    key: 'violationCount',
    name: '  Status',
    fieldName: 'Status',
    maxWidth: 150,
    minWidth: 150
  },
  {
    key: 'actionRequired',
    name: '  Action Required',
    fieldName: 'Action Required',
    maxWidth: 150,
    minWidth: 150
  },
  {
    key: 'quarantineDate',
    name: 'Due Date(UTC)',
    fieldName: 'quarantineDate',
    maxWidth: 150,
    minWidth: 150
  }
];
  
  export const ADMIN_PBI_URL = ''; // TODO: Get the correct URLs for this constant.
  export const ADMIN_DELETED_PBI_URL = ''; // TODO: Get the correct URLs for this constant.
  