/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import { Field, useField, useFormikContext } from 'formik';
import { css, Label, Link, MessageBar, MessageBarType, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { map } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { IEventTelemetry } from '@microsoft/applicationinsights-common';
import { EnvironmentRequestPlatformInformationFormStyle } from './EnvironmentRequestForm.styles';
import {
  ENVIRONMENT_TYPE_OPTIONS,
  ENVIRONMENT_REGION_OPTIONS,
  ENVIRONMENT_PURPOSE_OPTIONS,
  ENVIRONMENT_SKU_OPTIONS,
  ENVIRONMENT_DLP_POLICY_OPTIONS,
  ENVIRONMENT_CURRENCY_OPTIONS,
  ENVIRONMENT_LANGUAGE_OPTIONS
} from '../EnvironmentRequestFormConstants';
import { EnvironmentRequestPlatformInformationFormProps } from '../EnvironmentRequestForm.types';
import { FormFieldWithTooltip } from '../../FormFieldWithTooltip';
import { httpPost } from '../../../../shared/services/RestApi';
import { checkMembersInSecurityGroupUrl } from '../../../../shared/services/Api';
import { ICheckMemberInSGResponse, checkMemberInSGResponseInitiaValues } from '../../../../shared/models/Environment/ICheckMemberInSGResponse';
import { TelemetryService } from '../../../TelemetryService/TelemetryService';
import { EVENTS, PAGES } from '../../../../shared/constants/Telemetry.constants';
import { getCachedUser } from '../../../../msalConfig';

export const EnvironmentRequestPlatformInformationForm = (props: EnvironmentRequestPlatformInformationFormProps) => {
  const {
    formField: {
      environmentType,
      environmentPurpose,
      sku,
      requestedDLPPolicy,
      CDSidentifier,
      region,
      currency,
      language,
      existingSecurityGroup
    }
  } = props;
  const [environmentTypeField] = useField(environmentType.name);
  const [dlpPolicyField, _, dlpPolicyFieldHelpers] = useField(requestedDLPPolicy.name);
  const [existingSecurityGroupField] = useField(existingSecurityGroup.name);
  const [isLoading, setIsLoading] = useState(false);
  // if (
  //   environmentTypeField.value === 'Dataverse Org' &&
  //   dlpPolicyField.value !== 'CSEO Medium' &&
  //   dlpPolicyField.value !== 'CSEO Medium Isolated'
  // ) {
  //   dlpPolicyFieldHelpers.setValue('CSEO Medium');
  // }

  const [skuField, _s, skuFieldHelpers] = useField(sku.name);
  const isD365EnvironmentType = environmentTypeField.value === 'D365';

  useEffect(()=>{    
    if(isD365EnvironmentType){
      skuFieldHelpers.setValue('Production')
    }
    props.setIsD365EnvironmentVaild(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[environmentTypeField.value])

  const validateD365AndSandboxError = () => {
    return (
      <MessageBar messageBarType={MessageBarType.error}>
        {(skuField.value === 'Sandbox' && isD365EnvironmentType) ? (
          <div>
            Sandbox cannot be used for D365 environments. Learn more
            <Link href = "https://microsoft.sharepoint.com/sites/Microsoft365Builders/SitePages/Power%20Platform%20Gov/PowerPlatform-Environments.aspx#custom-environments-(sandbox-production)" target="_blank" rel='noreferrer noopener'> 
              here 
            </Link>
          </div>
        ) : null}
      </MessageBar>
    )
  }

  const setSecurityGroupChanged = () =>{ 
    props.setIsMemberOfSecurityGroupValid(false);
    props.setIsMemberOfSecurityGroupResponse(checkMemberInSGResponseInitiaValues);
  }

  useEffect(()=>{
    if(existingSecurityGroupField.value === ''){
      props.setIsMemberOfSecurityGroupValid(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[existingSecurityGroupField.value])

  useEffect(() => {
    if(isD365EnvironmentType){
      if(skuField.value === 'Production'){
        props.setIsD365EnvironmentVaild(true);
      }
      else{
        props.setIsD365EnvironmentVaild(false);
      }
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[skuField.value])

  const getSecurityGroupValidationErrorText = ():string =>{
    if(!props.isMemberOfSecurityGroupResponse.isSecurityGroupValid){
      return props.isMemberOfSecurityGroupResponse.securityGroupErrorResponse?.toString();
    }
    return `The member(s) ${props.isMemberOfSecurityGroupResponse.nonMembersInSecurityGroup?.join(', ')} are not part of the Security group`;
  }

  const renderMessageBar = () => {
    if(isLoading){
      return(
        <Spinner size={SpinnerSize.medium}/>
      )
    }
    if(props.isMemberOfSecurityGroupValid && props.isMemberOfSecurityGroupResponse.isMemberInSecurityGroup && props.isMemberOfSecurityGroupResponse !== checkMemberInSGResponseInitiaValues){
      return (
        <MessageBar messageBarType={MessageBarType.success}>
          Validation completed successfully
        </MessageBar>
      )
    }
    if(props.isMemberOfSecurityGroupResponse !== checkMemberInSGResponseInitiaValues && (!props.isMemberOfSecurityGroupResponse.isMemberInSecurityGroup ||  !props.isMemberOfSecurityGroupResponse.isSecurityGroupValid)){
    return (
      <MessageBar messageBarType={MessageBarType.error}>
        {getSecurityGroupValidationErrorText()}
      </MessageBar>
    )
   }
    return (
      <MessageBar messageBarType={MessageBarType.info}>
        Please validate Security Group before proceeding
      </MessageBar>
    );
  }

  const checkMembersInSecurityGroup = async(
    securityGroup: string,
    members: string
  ): ICheckMemberInSGResponse | Promise<ICheckMemberInSGResponse> => {
    const query = {
      securityGroup: securityGroup,
      primaryFTEs: members
    };
    const callBack = (res: AjaxResponse) => {
      props.setIsMemberOfSecurityGroupResponse(res.response);
      setIsLoading(false);
      if(res.response?.isMemberInSecurityGroup){
        props.setIsMemberOfSecurityGroupValid(true);
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return res.response as ICheckMemberInSGResponse; 
    }
    // eslint-disable-next-line no-return-await
    return await httpPost(checkMembersInSecurityGroupUrl(), query).pipe(map(callBack)).toPromise();
  };
  
   const environmentRequestValues = useFormikContext();
   useEffect(() => {
    if(environmentTypeField.value === 'Power Platform(without Dataverse)'){
        props.setIsMemberOfSecurityGroupValid(true);
    }else  if(existingSecurityGroupField.value !== '' ){
          props.setIsMemberOfSecurityGroupValid(false);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   },[environmentRequestValues.values.primaryFTEContacts,environmentTypeField.value])

  return (
    <>
      <div
        className={css(
          'Environment-Request-Platform-Information-Form-Field',
          EnvironmentRequestPlatformInformationFormStyle.wrapper
        )}
      >
        <div
          className={css(
            'GeneralInfoWrapper-Form-Field',
            EnvironmentRequestPlatformInformationFormStyle.generalInfoWrapper
          )}
        >
          <div
            className={css('EnvironmentType-Form-Field', EnvironmentRequestPlatformInformationFormStyle.formColNonCDS)}
          >
            <FormFieldWithTooltip
              as={environmentType.component}
              label={environmentType.label}
              name={environmentType.name}
              aria-label={environmentType.label}
              aria-labelledby={environmentType.label}
              ariaRequired={environmentType.required}
              required={environmentType.required}
              options={ENVIRONMENT_TYPE_OPTIONS}
              tooltip={environmentType.tooltip || ''}
            />
          </div>
          <div className={css('Requester-Form-Field', EnvironmentRequestPlatformInformationFormStyle.formColNonCDS)}>
            <FormFieldWithTooltip
              as={region.component}
              label={region.label}
              aria-label={region.label}
              aria-labelledby={region.label}
              ariaRequired={region.required}
              name={region.name}
              required={region.required}
              options={ENVIRONMENT_REGION_OPTIONS}
              tooltip={region.tooltip || ''}
            />
          </div>
          <div
            className={css(
              'EnvironmentPurpose-Form-Field',
              EnvironmentRequestPlatformInformationFormStyle.formColNonCDS
            )}
          >
            <Field
              as={environmentPurpose.component}
              label={environmentPurpose.label}
              name={environmentPurpose.name}
              required={environmentPurpose.required}
              options={ENVIRONMENT_PURPOSE_OPTIONS}
              ariaRequired={environmentPurpose.required}
            />
          </div>
          <div className={css('Sku-Form-Field', EnvironmentRequestPlatformInformationFormStyle.formColNonCDS)}>
            <FormFieldWithTooltip
              as={sku.component}
              label={sku.label}
              name={sku.name}
              required={sku.required}
              ariaRequired={sku.required}
              options={ENVIRONMENT_SKU_OPTIONS}
              tooltip={sku.tooltip || ''}
            />
          </div>
          {isD365EnvironmentType && skuField.value === 'Sandbox' ? (
            <Stack>
              <div className={css('Requester-Form-Field', EnvironmentRequestPlatformInformationFormStyle.formMessageBox)}>
                {validateD365AndSandboxError()}
              </div>
          </Stack>
          ) : null}
          
          <div className={css('Requester-Form-Field', EnvironmentRequestPlatformInformationFormStyle.formColNonCDS)}>
            <FormFieldWithTooltip
              as={requestedDLPPolicy.component}
              label={requestedDLPPolicy.label}
              aria-label={requestedDLPPolicy.label}
              aria-labelledby={requestedDLPPolicy.label}
              ariaRequired={requestedDLPPolicy.required}
              name={requestedDLPPolicy.name}
              required={requestedDLPPolicy.required}
              options={ENVIRONMENT_DLP_POLICY_OPTIONS}
              disabled = {true}
              tooltip={requestedDLPPolicy.tooltip || ''}
            />
          </div>
        </div>

        {environmentTypeField.value !== 'Power Platform(without Dataverse)' ? (
          <div className={css('DataVerse-Form-Field', EnvironmentRequestPlatformInformationFormStyle.dataVerseWrapper)}>
            <Label>Dataverse and Dynamics</Label>
            <div className={css('DataVerse-Content', EnvironmentRequestPlatformInformationFormStyle.dataVerseContent)}>
              <div className={css('Requester-Form-Field', EnvironmentRequestPlatformInformationFormStyle.formColCDS)}>
                <Field
                  as={currency.component}
                  label={currency.label}
                  name={currency.name}
                  required={currency.required}
                  options={ENVIRONMENT_CURRENCY_OPTIONS}
                  ariaRequired={currency.required}
                />
              </div>
              <div className={css('Requester-Form-Field', EnvironmentRequestPlatformInformationFormStyle.formColCDS)}>
                <Field
                  as={language.component}
                  label={language.label}
                  name={language.name}
                  required={language.required}
                  ariaRequired={language.required}
                  options={ENVIRONMENT_LANGUAGE_OPTIONS}
                />
              </div>
              <div className={css('Requester-Form-Field', EnvironmentRequestPlatformInformationFormStyle.formColCDS)}>
                <FormFieldWithTooltip
                  as={existingSecurityGroup.component}
                  label={existingSecurityGroup.label}
                  name={existingSecurityGroup.name}
                  required={existingSecurityGroup.required}
                  tooltip={existingSecurityGroup.tooltip || ''}
                  ariaRequired={existingSecurityGroup.required}
                  onChange={setSecurityGroupChanged}
                />
              </div>
              { existingSecurityGroupField.value !== '' &&
              <div>
              <Stack>
              <div className={css('Requester-Form-Field', EnvironmentRequestPlatformInformationFormStyle.formMessageBox)}>
                {renderMessageBar()}
              </div>
              <div className={css('Requester-Form-Field', EnvironmentRequestPlatformInformationFormStyle.formValidateBtn)}>
                <PrimaryButton
                  id="securityGroupValidationBtn"
                  aria-label='SecurityGroupValidationBtn'
                  onClick={() => {
                    TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
                      page: PAGES.ENVIRONMENT_NEW,
                      actionName: "Validate Security Group",
                      actionType: EVENTS.BUTTON_CLICK,
                      username: getCachedUser()?.username
                    });
                    setIsLoading(true);
                    checkMembersInSecurityGroup(
                      existingSecurityGroupField.value,
                      `${environmentRequestValues.values?.requestorEmail};${environmentRequestValues.values?.primaryFTEContacts}`
                    );
                  }}
                >
                  Click to validate
                </PrimaryButton>
              </div>
              </Stack>
              </div>
              }
              <div className={css('Requester-Form-Field', EnvironmentRequestPlatformInformationFormStyle.formColCDS)}>
                <Field
                  as={CDSidentifier.component}
                  label={CDSidentifier.label}
                  name={CDSidentifier.name}
                  required={CDSidentifier.required}
                  ariaRequired={CDSidentifier.required}
                />
                <b>{'.crm.dynamics.com'}</b>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
