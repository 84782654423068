import { createReducer } from 'typesafe-actions';
import { IEventTelemetry, IExceptionTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import {
  searchServiceTreeObject,
  searchServiceTreeObjectSuccess,
  searchServiceTreeObjectFail
} from '../../actions/serviceTree';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { API_NAMES, API_STATUS, EVENTS } from '../../../constants';

export const getServiceTreeObjectReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(searchServiceTreeObject, (state) => {
    return {
      ...state,
      serviceTreeState: {
        ...state.serviceTreeState,
        isLoading: true,
        loadingState: LoadingState.loading,
        serviceTreeList: [],
        fieldName: ''
      }
    };
  })
  .handleAction(searchServiceTreeObjectSuccess, (state, action) => {
    TelemetryService.trackEvent({ name: EVENTS.API_CALL } as IEventTelemetry, {
      API: API_NAMES.SEARCH_SERVICE_TREE_OBJECT,
      Result: API_STATUS.SUCCESS
    });
    return {
      ...state,
      serviceTreeState: {
        ...state.serviceTreeState,
        fieldName: action.payload.fieldName,
        serviceTreeList: [...action.payload.serviceTreeResult],
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(searchServiceTreeObjectFail, (state, action) => {
    TelemetryService.trackException({
      exception: { message: API_STATUS.FAIL, name: API_NAMES.SEARCH_SERVICE_TREE_OBJECT } as Error,
      properties: { API: API_NAMES.SEARCH_SERVICE_TREE_OBJECT, Payload: action.payload },
      severityLevel: SeverityLevel.Error
    } as IExceptionTelemetry);
    return {
      ...state,
      serviceTreeState: {
        ...state.serviceTreeState,
        isLoading: false,
        loadingState: LoadingState.error,
        loadingError: action.payload
      }
    };
  });
