import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { fluentBreadcrumb, fluentBreadcrumbItem, provideFluentDesignSystem } from '@fluentui/web-components';
import {
  Label,
  ScrollablePane,
  Stack,
  Separator,
  ShimmeredDetailsList,
  SelectionMode,
  IColumn,
  Icon,
  Spinner,
  SpinnerSize,
  Link,
  Breadcrumb,
  IBreadcrumbItem,
  css,
  MessageBar,
  MessageBarType,
  PrimaryButton
} from '@fluentui/react';
import { IPageViewTelemetry } from '@microsoft/applicationinsights-common/types/Interfaces/IPageViewTelemetry';
import { IEventTelemetry } from '@microsoft/applicationinsights-common';
import { _Styles } from '../../../../shared/styles/Page.styles';
import { violaltionStyle, endSpacingTokens } from './Environment.style';
import { environmentPolicyColumns } from './EnvironmentViolation.column';
import {
  IEnvironmentDetailProps,
  IEnvironmentBasicDetail,
  IEnvironmentViolationPolicy
} from './EnvironmentViolation.model';
import { EnvironmentViolationDetail } from './EnvironmentViolationDetail';
import { EnvironmentAttestationPanel } from '../Panels/EnvironmentAttestationPanel';
import { getEnvironmentDetail, rescanEnvironment } from '../../../../shared/store/actions';
import { IExecutedPolicies } from '../../../../shared/models/Environment/IExecutedPolices';
import { IEnvironmentAttestation } from '../../../../shared/models/Environment/IEnvironmentAttestationRequest';
import {
  fetchFailedExecutedPolicies,
  getDueDateString,
  getStatusFlag,
  selectClass
} from './EnvironmentViolation.helper';
import { IRootState } from '../../../../shared/store/IRootState';
import { LoadingState } from '../../../../shared/models/ILoading';
import { ADMIN_ENV_URL, ENV_CONST } from '../Environment.constants';
import { IRescan } from '../../../../shared/models/rescan/IRescan';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { EVENTS, PAGES } from '../../../../shared/constants/Telemetry.constants';
import { getCachedUser } from '../../../../msalConfig';

provideFluentDesignSystem().register(
  fluentBreadcrumb(),
  fluentBreadcrumbItem({
    separator: `<div style="font-size: 20px;
      color: #605e5c; margin-left: -2px;">></div>`
  })
);

export const EnvironmentViolations: React.FunctionComponent<IEnvironmentDetailProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const { environmentId }: { [key: string]: string } = useParams();
  const policiesRequestState = useSelector((state: IRootState) => state.policiesRequestState);
  const dispatch = useDispatch();
  const [basicDetails, setBasicDetails] = useState<IEnvironmentBasicDetail | null>(null);
  const [envName, setEnvName] = useState<string>('');
  const [failedPolicies, setFailedPolicies] = useState<IEnvironmentViolationPolicy[]>([]);
  const { getEnvironmentDetailRequestState } = props;
  const [envDetails, setEnvDetails] = useState<IEnvironmentAttestation | null>(null);
  const [isRescan, setIsRescan] = useState(false);

  const submitEnvironmentRequestState = useSelector((state: IRootState) => state.setEnvironmentRescanRequestState);

  const goBack = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    history.push('/power-platform/environments');
  };

  const updateViolationState = useCallback(() => {
    dispatch(getEnvironmentDetail(environmentId));
  }, [dispatch, environmentId]);

  const items: IBreadcrumbItem[] = [
    { text: 'Environments', key: 'environments', onClick: goBack },
    { text: envName, key: `${environmentId}` }
  ];
  
  const onRenderItemColumn = (item: IEnvironmentViolationPolicy, index: number, column: IColumn) => {
    const fieldContent = item[column.fieldName as keyof IEnvironmentViolationPolicy] as string;
    switch (column.key) {
      case 'info':
        return (
          <Link
            rel="noopener noreferrer"
            style={{ lineHeight: '40px', fontSize: '14px' }}
            target="_blank"
             href={item.info}
             onClick={() => {
              TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
                page: PAGES.ENVIRONMENT_VIOLATIONS,
                actionName: "How To fix",
                actionType: EVENTS.BUTTON_CLICK,
                id: basicDetails?.id,
                username: getCachedUser()?.username
              });
            }}
          >
            How To fix
          </Link>
        );
      case 'action':
        return envDetails && item.id === 'Governance.Core.Policies.AttestationPolicy' ? (
          <EnvironmentAttestationPanel
            selectedEnvironmentId={environmentId}
            btnText="Remediate"
            attestationDetails={envDetails}
            updateViolationState={updateViolationState}
          />
        ) : (
          <PrimaryButton
            onClick={() => {
              TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
                  page: PAGES.ENVIRONMENT_VIOLATIONS,
                  actionName: "Open Environment",
                  actionType: EVENTS.BUTTON_CLICK,
                  id: basicDetails?.id,
                  username: getCachedUser()?.username
              });
              const url = `${ADMIN_ENV_URL}/${basicDetails?.id}/hub`;
              window.open(url);
            }}
            target="_blank"
            rel="noopener noreferrer"
            style={{width:'130px'}}
          >
            Open Environment
          </PrimaryButton>
        );
      default:
        return <p style={{ whiteSpace: 'pre-wrap', fontSize: '14px' }}>{fieldContent}</p>;
    }
  };

  useEffect(() => {
    dispatch(getEnvironmentDetail(environmentId));
  }, [environmentId, dispatch]);
    
  useEffect(()=>{
    TelemetryService.trackPageView({
      name: PAGES.ENVIRONMENT_VIOLATIONS,
      uri: window.location.href,
    } as IPageViewTelemetry);
  },[]);

  useEffect(() => {
    if(submitEnvironmentRequestState?.isLoading)
    setIsRescan(false);
  }, [submitEnvironmentRequestState]);

  useEffect(() => {
    if (policiesRequestState.loadingState === LoadingState.loaded && policiesRequestState.data) {
      if (getEnvironmentDetailRequestState.data && getEnvironmentDetailRequestState.data.id) {
        const data = getEnvironmentDetailRequestState.data;
        const basicDetail: IEnvironmentBasicDetail = {
          id: data.id,
          assetUri: data.assetUri,
          dueDate: data.quarantineDate,
          desc: data.description,
          displayName: data.environmentName
        };
        setFailedPolicies(
          fetchFailedExecutedPolicies(data.executedPolicies as IExecutedPolicies[], policiesRequestState.data)
        );
        setEnvName(data.environmentName);
        setBasicDetails(basicDetail);
        setEnvDetails(data as IEnvironmentAttestation);
      }
    }
  }, [getEnvironmentDetailRequestState, policiesRequestState]);

  const rescanEnviroment = () => {
    TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
      page: PAGES.ENVIRONMENT_VIOLATIONS,
      actionName: "Rescan",
      actionType: EVENTS.BUTTON_CLICK,
      id: envDetails?.id,
      username: getCachedUser()?.username
    });
    setIsRescan(true);
    const envIds: IRescan = {
      assetIds : [ envDetails?.id ]
    };
    dispatch(rescanEnvironment(envIds));
  }

  return getEnvironmentDetailRequestState.isLoading ? (
    <Stack className={violaltionStyle.spinnerCenter}>
      <Spinner size={SpinnerSize.large} />
    </Stack>
  ) : (
    <ScrollablePane className={props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand} aria-label="Environments List" role="region">
      <div id='no-horizontal-scroll' className={css(violaltionStyle.noHorizontalScroll)}>
       {getEnvironmentDetailRequestState.data.id && (
        <Stack>
          <Stack horizontal horizontalAlign="space-between" style={{ marginTop: '20px' }}>
            <div className={violaltionStyle.displaySpaceBetween}>
              <Stack.Item>
                  <fluent-breadcrumb>
                    <fluent-breadcrumb-item onClick={goBack}>
                      <div className={css(violaltionStyle.breadcrumb, violaltionStyle.distanceToSidePannel)}>
                        Environments
                      </div>
                    </fluent-breadcrumb-item>
                    <fluent-breadcrumb-item>
                      <div className={violaltionStyle.priorBreadcrumb}>{basicDetails?.displayName}</div>
                      <div>
                        <MessageBar messageBarType={selectClass(envDetails?.complianceState)}>
                          {ENV_CONST.ApiStatus[envDetails?.complianceState]}
                        </MessageBar>
                      </div>
                      {/* TO DO: If custom lable reqiured at front of breadcrumb */}
                      {/* {ENV_CONST.ApiStatus[envDetails?.complianceState] === ENV_CONST.ApiStatus.InViolation && (
                        <div className={violaltionStyle.breadcrumbComplianceState}>
                          <span>{ENV_CONST.ApiStatus[envDetails?.complianceState]}</span>
                        </div>
                      )} */}
                    </fluent-breadcrumb-item>                    
                  </fluent-breadcrumb>
              </Stack.Item>
              <div className={violaltionStyle.m5}>
    <Stack horizontal tokens={endSpacingTokens}>
                  <span className={violaltionStyle.platformLabel}>Power Platform</span>
                  <Label style={{ lineHeight: '25px', fontSize: '14px' }}>Environment</Label>
                </Stack>
              </div>
            </div>
          </Stack>
          <div>
            <EnvironmentViolationDetail basicDetails={basicDetails} />
            {ENV_CONST.ApiStatus[envDetails?.complianceState] === ENV_CONST.ApiStatus.Quarantined && (
              <div className={violaltionStyle.infoLabelWrapper}>
                <Icon iconName="Info" className={violaltionStyle.infoIcon} />
              </div>
            )}
            {getStatusFlag(envDetails?.complianceState) && (
              <div className={violaltionStyle.infoLabelWrapper}>
                <MessageBar messageBarType={MessageBarType.warning} style={{ fontSize: '14px' }}>
                  {getStatusFlag(envDetails?.complianceState)}
                </MessageBar>
              </div>
            )}
            <Separator style={{ fontWeight: '700', backgroundColor: '#e8e8e8 !important' }} />
            {envDetails?.complianceState !== ENV_CONST.ApiStatus.Compliant &&
            <div id='Environment-violation-details'>
            <Stack horizontal horizontalAlign="space-between">
              <Label className={violaltionStyle.distanceToSidePannel}>Policy Violations</Label>
              <Stack horizontal
                className={violaltionStyle.rescan}
                >
                {isRescan ? (
                  <Spinner size={SpinnerSize.small} style={{ margin: '10px 0px 10px 0px' }} />
                ) : (
                  <Icon 
                    iconName="refresh" 
                    className={violaltionStyle.rescanIconStyle}
                    onClick={rescanEnviroment} 
                    tabIndex={0}
                    aria-label='Re-scan'
                    aria-labelledby='Re-scan'
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' || event.key === ' ') {
                          event.preventDefault();
                          rescanEnviroment();
                      }
                    }}
                    />
                )}
                <Label style={{ margin: '2px 4px'}}>Re-scan</Label>
              </Stack>
            </Stack>
            {basicDetails?.dueDate && (
              <Stack horizontal>
                <Icon iconName="DateTime" className={violaltionStyle.dueDateIcon} />
                <div className={violaltionStyle.dueDate}>
                  <Label className={violaltionStyle.p0}>Due Date(UTC):</Label>
                  <span className={violaltionStyle.dueDateValue}>{getDueDateString(basicDetails.dueDate)}</span>
                </div>
              </Stack>
            )}
            <div>
              {failedPolicies.length !== 0 && (
                <ShimmeredDetailsList
                  setKey="items"
                  items={failedPolicies}
                  columns={environmentPolicyColumns}
                  selectionMode={SelectionMode.none}
                  onRenderItemColumn={onRenderItemColumn}
                  // enableShimmer={!items}
                  ariaLabelForShimmer="Content is being fetched"
                  ariaLabelForGrid="Item details"
                />
              )}
            </div>
            </div>
            }
          </div>
        </Stack>
      )}
      </div>
    </ScrollablePane>
  );
};
