import { INavLink, INavLinkGroup } from '@coherence-design-system/controls';
import { RouteProps } from 'react-router-dom';
import { IEventTelemetry } from '@microsoft/applicationinsights-web';
import { NavigationProps } from './Navigation.types';
import { SPS_LIST_FF, PBI_WORKSPACES_FF } from '../../shared/store/constants';
import { TelemetryService } from '../TelemetryService/TelemetryService';
import { EVENTS, PAGES } from '../../shared/constants/Telemetry.constants';
import { getCachedUser } from '../../msalConfig';

const links: INavLink[] = [];
export const authzRoutes: RouteProps[] = [];

export const addRoute = (route: RouteProps) => {
  authzRoutes.push(route);
};

export const addLink = (linkToAdd: INavLink) => {
  links.push(linkToAdd);
};

export const getNavigation = (props: NavigationProps): INavLinkGroup[] => {
  const navLinkGroups: INavLinkGroup[] = [
    {
      key: 'Left Navigation Menu',
      links: [
        {
          name: 'Home',
          key: 'HomeLink1',
          ariaLabel: 'homestuff',
          icon: 'Home',
          'data-id': 'automation_id_22230',
          target: '_blank',
          onClick: () => {
            TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
                page: PAGES.CONTAINER,
                actionName: "Home",
                actionType: EVENTS.LINK_CLICK,
                username: getCachedUser()?.username
            });
            props.history.push('/');
          },
          isSelected: window.location.href === window.location.protocol + '//' + window.location.host + '/'
        },
        {
          name: 'Request Status',
          key: 'MyRequestStatusesLink',
          ariaLabel: 'My Request Status',
          icon: 'Headset',
          target: '_blank',
          onClick: () => {
            TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
              page: PAGES.CONTAINER,
              actionName: "Request Status",
              actionType: EVENTS.LINK_CLICK,
              username: getCachedUser()?.username
            });
            props.history.push('/list-request-status');
          },
          isSelected:
            window.location.href === window.location.protocol + '//' + window.location.host + '/list-request-status'
        }
      ]
    },
    {
      key: 'Left Navigation Power Platform Menu',
      name: 'Power Platform',
      links: [
        {
          name: 'Environment',
          key: 'Environment',
          icon: 'AddOnlineMeeting',
          onClick: (_ev?: React.MouseEvent<HTMLElement>, _item?: INavLink) => {
             // console.log(item);
          },
          links: [
                  {
                    name: 'New',
                    key: 'NewEnvironmentRequestLink',
                    ariaLabel: 'New Environment Request',
                    target: '_blank',
                    onClick: () => {
                      TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
                          page: PAGES.CONTAINER,
                          actionName: "New Environment Request",
                          actionType: EVENTS.LINK_CLICK,
                          username: getCachedUser()?.username
                      });
                      props.history.push('/environment-request');
                    },
                    isSelected:
                      window.location.href ===
                      window.location.protocol + '//' + window.location.host + '/environment-request'
                  },
                  {
                    name: 'View',
                    key: 'ViewEnvironmentsLink',
                    ariaLabel: 'View Environments Link',
                    // target: '_blank',
                    onClick: () => {
                      TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
                          page: PAGES.CONTAINER,
                          actionName: "View Environments",
                          actionType: EVENTS.LINK_CLICK,
                          username: getCachedUser()?.username
                      });
                      props.history.push('/power-platform/environments');
                    },
                    isSelected:
                      window.location.href ===
                      window.location.protocol + '//' + window.location.host + '/power-platform/environments'
                  }
                ]
        }
      ]
    },
    ...(props.featureFlags?.[SPS_LIST_FF]
      ? [
          {
            key: 'Left Navigation Shared Workspaces Menu',
            name: 'Shared Workspaces',
            links: [
              {
                name: 'SharePoint Sites',
                key: 'Sharepointsites',
                icon: 'SharepointAppIcon16',
                'data-id': 'sharepoint-parent-link',
                onClick: (_ev?: React.MouseEvent<HTMLElement>, _item?: INavLink) => {
                  // console.log(item);
                },
                links: [
                  {
                    name: 'View',
                    key: 'ViewSharepointSitesLink',
                    ariaLabel: 'View Sharepoint Sites Link',
                    'data-id': 'sharepoint-child-link',
                    onClick: () => {
                      TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
                          page: PAGES.CONTAINER,
                          actionName: "View Sharepoint Sites",
                          actionType: EVENTS.LINK_CLICK,
                          username: getCachedUser()?.username
                      });
                      props.history.push('/shared-workspaces/sharepointsites');
                    },
                    isSelected:
                      window.location.href ===
                      window.location.protocol + '//' + window.location.host + '/shared-workspaces/sharepointsites'
                  }
                ]
              }
            ]
          }
        ]
      : []),
    ...(props.featureFlags?.[PBI_WORKSPACES_FF]
      ? [
          {
            key: 'Left Navigation PBI Workspaces Menu',
            name: 'PBI Workspaces',
            links: [
              {
                name: 'PBI Workspaces',
                key: 'PBIWorkspaces',
                icon: 'PowerBILogo16',
                'data-id': 'powerbi-parent-link',
                onClick: (_ev?: React.MouseEvent<HTMLElement>, _item?: INavLink) => {
                  // console.log(item);
                },
                links: [
                  {
                    name: 'View',
                    key: 'ViewPBIWorkspacesLink',
                    ariaLabel: 'View PowerBI Workspaces Link',
                    'data-id': 'powerbi-child-link',
                    onClick: () => {
                      TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
                          page: PAGES.CONTAINER,
                          actionName: "View PBI Workspaces",
                          actionType: EVENTS.LINK_CLICK,
                          username: getCachedUser()?.username
                      });
                      props.history.push('/pbi-workspaces/pbiworkspaces');
                    },
                    isSelected:
                      window.location.href ===
                      window.location.protocol + '//' + window.location.host + '/pbi-workspaces/pbiworkspaces'
                  }
                ]
              }
            ]
          }
        ]
      : []),
    {
      key: 'Left Navigation Mailbox SSS Menu',
      name: 'Mailbox SSS',
      links: [
        {
          name: 'Mailbox Request',
          key: 'MailboxRequestLink',
          ariaLabel: 'Mailbox Request',
          icon: 'mail',
          target: '_blank',
          onClick: () => {
            TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
                page: PAGES.CONTAINER,
                actionName: "Mailbox Request",
                actionType: EVENTS.LINK_CLICK,
                username: getCachedUser()?.username
            });
            props.history.push('/mailbox-request/create');
          },
          isSelected:
            window.location.href ===
            window.location.protocol + '//' + window.location.host + '/mailbox-request/create'
        },
        {
          name: 'Global Admin Approval',
          key: 'MailboxSSSGAApproval',
          ariaLabel: 'Global Admin Approval',
          icon: 'UserFollowed',
          target: '_blank',
          onClick: () => {
            TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
                page: PAGES.CONTAINER,
                actionName: "Global Admin Approval",
                actionType: EVENTS.LINK_CLICK,
                username: getCachedUser()?.username
            });
            props.history.push('/mailbox-sss-ga-approval');
          },
          isSelected:
            window.location.href ===
            window.location.protocol + '//' + window.location.host + '/mailbox-sss-ga-approval'
        }
      ]
    }
  ];
  return navLinkGroups;
};