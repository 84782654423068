import { createReducer } from 'typesafe-actions';
import {
  setEnvironmentAttestation,
  setEnvironmentAttestationFailed,
  setEnvironmentAttestationSucceeded
} from '../../actions/environment/environmentAttestation';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { API_NAMES } from '../../../constants';

export const setEnvironmentAttestationReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(setEnvironmentAttestation, (state) => {
    return {
      // initial state for the request
      ...state,
      setEnvironmentAttestationRequestState: {
        ...state.setEnvironmentAttestationRequestState,
        isLoading: true,
        loadingState: LoadingState.loading,
        startTime: performance.now()
      }
    };
  })
  .handleAction(setEnvironmentAttestationSucceeded, (state, data) => {
    TelemetryService.trackApiSuccess(API_NAMES.SET_ENVIRONMENT_ATTESTATION, state.setEnvironmentAttestationRequestState.startTime, {ActionType: data.payload.data?.actionType});
    return {
      ...state,
      setEnvironmentAttestationRequestState: {
        // Override this object in the state spread
        ...state.setEnvironmentAttestationRequestState,
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(setEnvironmentAttestationFailed, (state, action) => {
    TelemetryService.trackApiFailure(API_NAMES.SET_ENVIRONMENT_ATTESTATION, state.setEnvironmentAttestationRequestState.startTime, action.payload)
    return {
      ...state,
      setEnvironmentAttestationRequestState: {
        ...state.setEnvironmentAttestationRequestState,
        isLoading: false,
        loadingState: LoadingState.error,
        loadingError: action.payload
      }
    };
  });
