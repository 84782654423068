import * as React from 'react';
import { CoherenceHeader } from '@coherence-design-system/controls';
import { Icon, Separator, Stack, Text } from '@fluentui/react';
import { IEventTelemetry } from '@microsoft/applicationinsights-common';
import { IconStyle, StackStyle, TextStyle } from './Header.styles';
import { getCachedUser, logout } from '../../msalConfig';
import { TelemetryService } from '../TelemetryService/TelemetryService';
import { EVENTS, PAGES } from '../../shared/constants/Telemetry.constants';

interface HeaderState {
  showDismissAllBool?: boolean;
  ocvButtonIsFocused?: boolean;
}

interface HeaderProps {}

export class Header extends React.Component<HeaderProps, HeaderState> {
  // constructor(props: HeaderProps) {
  //   super(props);
  // }

  renderHelpBody = () => {
    function logTelemetry(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, actionName: string): void {
      TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
            page: PAGES.CONTAINER,
            actionName: actionName,
            actionType: EVENTS.LINK_CLICK,
            username: getCachedUser()?.username
        });
    }

    return (
      <div style={{marginTop:10}}>
        <div>
          <Stack horizontal styles={StackStyle}>
            <Icon iconName="Documentation" aria-label="Documentation Icon" styles={IconStyle} />
            <a
              target="_blank"
              rel="noreferrer noopener"
              onClick={(e) => logTelemetry(e, "Documentation")}
              href="https://microsoft.sharepoint.com/sites/Microsoft365Builders/SitePages/Power%20Platform%20Gov/PowerPlatform-Wiki.aspx"
            >
              Documentation
            </a>
          </Stack>
        </div>
        <div>
          <Stack horizontal styles={StackStyle}>
            <Icon iconName="Lock" aria-label="Privacy Icon" styles={IconStyle} />
            <a target="_blank" rel="noreferrer noopener" onClick={(e) => logTelemetry(e, "Privacy")} href="https://privacy.microsoft.com/en-US/privacystatement">
              Privacy
            </a>
          </Stack>
        </div>
        <div>
          <Stack horizontal styles={StackStyle}>
            <Icon iconName="Shield" aria-label="Legal Icon" styles={IconStyle} />
            <a target="_blank" rel="noreferrer noopener" onClick={(e) => logTelemetry(e, "Legal")} href="https://www.microsoft.com/licensing/docs">
              Legal
            </a>
          </Stack>
        </div>
        <div>
          <Stack horizontal styles={StackStyle}>
            <Icon iconName="FeedbackRequestSolid" aria-label="FAQ Icon" styles={IconStyle} />
            <a
              target="_blank"
              rel="noreferrer noopener"
              onClick={(e) => logTelemetry(e, "FAQ")}
              href="https://microsoft.sharepoint.com/sites/Microsoft365Builders/SitePages/Power%20Platform%20Gov/PowerPlatform-FAQ.aspx"
            >
              FAQ
            </a>
          </Stack>
        </div>
        <Separator />
        <Text variant={'large'} block styles={TextStyle}>
          Need help?
        </Text>
        <div>
          <Stack horizontal styles={StackStyle}>
            <Icon iconName="SharepointAppIcon16" aria-label="Contact Support Icon" styles={IconStyle} />
            <a
              target="_blank"
              rel="noreferrer noopener"
              onClick={(e) => logTelemetry(e, "Contact Support")}
              href="https://microsoft.sharepoint.com/sites/Microsoft365Builders/SitePages/Power%20Platform%20Gov/PowerPlatform-Support.aspx"
            >
              Contact Support
            </a>
          </Stack>
        </div>
      </div>
    );
  };

  public render(): JSX.Element {
    return (
      <CoherenceHeader
        headerLabel={'Site banner'}
        appNameSettings={{
          label: 'Builders Hub'
        }}
        farRightSettings={{
          helpSettings: {
            panelSettings: {
              titleText: 'Help',
              body: this.renderHelpBody(),
            },
            buttonSettings: {
              title: 'Help',
              ariaLabel: 'Help',
            }
          },
          profileSettings: {
            panelSettings: {
              fullName: getCachedUser()?.name,
              emailAddress: getCachedUser()?.username,
              imageUrl: '',
              onLogOut: () => {
                TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
                  page: PAGES.CONTAINER,
                  actionName: "Logout",
                  actionType: EVENTS.LINK_CLICK,
                  username: getCachedUser()?.username
                });
                logout();
              },
              viewAccountLink: 'https://www.microsoft.com',
              logOutLink: '#',
              hasCloseButton: true,
              closeButtonAriaLabel: 'Close Panel',
            },
            buttonSettings: {
              title: 'Profile',
              ariaLabel: 'Profile',
            },
          }
        }}
      />
    );
  }
}
