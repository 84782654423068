import { createReducer } from 'typesafe-actions';
import { IEventTelemetry, IExceptionTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import { getAppMetadata, getAppMetadataFailed, getAppMetadataSucceeded } from '../../actions';
import { toastError } from '../../../utils/tools/toast';
import { initialAppMetadata } from '../../../models/AppsList/IAppMetadataRequest';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { API_NAMES, API_STATUS, EVENTS } from '../../../constants';

export const getAppMetadataReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(getAppMetadata, (state) => {
    return {
      // initial state for the request
      ...state,
      getAppMetadataRequestState: {
        ...state.getAppMetadataRequestState,
        data: initialAppMetadata,
        isLoading: true,
        loadingState: LoadingState.loading
      }
    };
  })
  .handleAction(getAppMetadataSucceeded, (state, action) => {
    TelemetryService.trackEvent({ name: EVENTS.API_CALL } as IEventTelemetry, {
      API: API_NAMES.GET_APP_METADATA,
      Result: API_STATUS.SUCCESS
    });
    return {
      ...state,
      getAppMetadataRequestState: {
        // Override this object in the state spread
        ...state.getAppMetadataRequestState,
        data: action.payload, // manually set these properties in the state
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(getAppMetadataFailed, (state, action) => {
    toastError('Failed to get app metadata', action.payload);
    TelemetryService.trackException({
      exception: { message: API_STATUS.FAIL, name: API_NAMES.GET_APP_METADATA } as Error,
      properties: { API: API_NAMES.GET_APP_METADATA, Payload: action.payload },
      severityLevel: SeverityLevel.Error
    } as IExceptionTelemetry);
    return {
      ...state,
      getAppMetadataRequestState: {
        ...state.getAppMetadataRequestState,
        isLoading: false,
        loadingState: LoadingState.error,
        loadingError: action.payload
      }
    };
  });
