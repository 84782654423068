import { createReducer } from 'typesafe-actions';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { API_NAMES } from '../../../constants';
import { rescanEnvironment, rescanEnvironmentFailed, rescanEnvironmentSucceeded } from '../../actions';

export const SubmitEnvironmentRescanReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(rescanEnvironment, (state) => {
    return {
      // initial state for the request
      ...state,
      setEnvironmentRescanRequestState : {
        ...state.setEnvironmentRescanRequestState,
        isLoading: false,
        loadingState: LoadingState.loading
      }
    };
  })
  .handleAction(rescanEnvironmentSucceeded, (state) => {
    TelemetryService.trackApiSuccess(API_NAMES.SUBMIT_ENVIRONMENT_RESCAN, state.setEnvironmentRescanRequestState.startTime);
    return {
      ...state,
      setEnvironmentRescanRequestState: {
        ...state.setEnvironmentRescanRequestState,
        isLoading: true,
        loadingState: LoadingState.loaded,
        startTime: performance.now()
      }
    };
  })
  .handleAction(rescanEnvironmentFailed, (state, action) => {
    TelemetryService.trackApiFailure(API_NAMES.SUBMIT_ENVIRONMENT_RESCAN, state.setEnvironmentRescanRequestState.startTime, action.payload)
    return {
      ...state,
      set: {
        ...state
      }
    };
  });
