import {
  IStyle,
  mergeStyleSets,
  getTheme,
} from '@fluentui/react';

const theme = getTheme();
export const PBIWorkspaceStyle = mergeStyleSets({
  pageBackground: {
    position: 'relative',
    height: '100%',
    selectors: {
      ':global(.ms-DetailsList)': {
        overflow: 'visible'
      }
    }
  } as IStyle,
  listWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '2%'
  } as IStyle,
  btnRemediate: {
    borderRadius: '5px',
    border: 'solid 1px #D2D0CE',
    color: '#353434'
  } as IStyle,
  actionButton: {
    width: '75%',
    fontSize: 'small'
  } as IStyle,
  noHorizontalScroll: {
    overflowX:'hidden'
  } as IStyle
});

export const iconStyles = mergeStyleSets({
  statusWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    lineHeight: '14px'
  },
  nonCompliantLabel: {
    color: '#0B0080',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  compliantLabel: {
    color: 'black',
    fontWeight: 600
  },
  circle: {
    width: '8px',
    height: '8px',
    borderRadius: '10px',
    marginRight: '4px',
    marginTop: '3px'
  },
  markCompliant: {
    background: '#008000'
  },
  markNonCompliant: {
    background: '#FFA500'
  },
  markQuarantined: {
    background: '#D13438'
  },
  markDeleted: {
    background: '#a7a7a7'
  }
});