import { createReducer } from 'typesafe-actions';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import { getEnvironments, getEnvironmentsFail, getEnvironmentsSuccess } from '../../actions';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { API_NAMES } from '../../../constants';

export const getEnvironmentReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(getEnvironments, (state) => {
    return {
      ...state,
      getEnvironmentsState: {
        ...state.getEnvironmentsState,
        isLoading: true,
        loadingState: LoadingState.loading,
        startTime: performance.now()
      }
    };
  })
  .handleAction(getEnvironmentsSuccess, (state, action) => {
    TelemetryService.trackApiSuccess(API_NAMES.GET_ENVIRONMENTS, state.getEnvironmentsState.startTime);
    return {
      ...state,
      getEnvironmentsState: {
        ...state.getEnvironmentsState,
        environmentList: action.payload,
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(getEnvironmentsFail, (state, action) => {
    TelemetryService.trackApiFailure(API_NAMES.GET_ENVIRONMENTS, state.getEnvironmentsState.startTime, action.payload)
    return {
      ...state,
      getEnvironmentsState: {
        ...state.getEnvironmentsState,
        isLoading: false,
        loadingState: LoadingState.error,
        loadingError: action.payload
      }
    };
  });
