import * as React from 'react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { useBoolean } from '@uifabric/react-hooks';
import { IEventTelemetry, IPageViewTelemetry } from '@microsoft/applicationinsights-common';
import { IEnvironmentAttestationPanelProps } from './EnvironementAttestationPannel.model';
import { EnvironmentAttestationForm } from '../../../../components/Forms/EnvironmentAttestationForm/EnvironmentAttestation';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { EVENTS, PAGES } from '../../../../shared/constants/Telemetry.constants';
import { getCachedUser } from '../../../../msalConfig';

export const EnvironmentAttestationPanel: React.FunctionComponent<IEnvironmentAttestationPanelProps> = (props) => {
  const { selectedEnvironmentId, btnText, attestationDetails, updateViolationState } = props;
  const [isOpen, { setTrue: openAttestationPanel, setFalse: dismissPanel }] = useBoolean(false);

  const dismissEnvironmentModal = () => {
    dismissPanel();
  };
  
  React.useEffect(()=>{
    TelemetryService.trackPageView({
      name: PAGES.ENVIRONMENT_ATTESTATION,
      uri: window.location.href,
    } as IPageViewTelemetry);
  },[]);

  return (
    <div style={{ lineHeight: '55px' }}>
      <PrimaryButton text={btnText} onClick={() => {
        TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
          page: PAGES.ENVIRONMENT_VIOLATIONS,
          actionName: btnText,
          actionType: EVENTS.BUTTON_CLICK,
          username: getCachedUser()?.username
        });
        openAttestationPanel();
      }} style={{ width: '130px' }} />
      <Panel
        isOpen={isOpen}
        onDismiss={dismissPanel}
        type={PanelType.medium}
        closeButtonAriaLabel="Close"
        headerText="Environment Attestation Form"
      >
        <EnvironmentAttestationForm
          environmentId={selectedEnvironmentId || ''}
          attestationDetails={attestationDetails}
          onSubmitCallback={dismissEnvironmentModal}
          updateViolationState={updateViolationState}
          operationType='Attestation'
        />
      </Panel>
    </div>
  );
};
