import {
  ApplicationInsights,
  IMetricTelemetry,
  IExceptionTelemetry,
  IEventTelemetry,
  ITraceTelemetry,
  IPageViewTelemetry,
  SeverityLevel
} from '@microsoft/applicationinsights-web';
import { flatProperties } from './TelemetryUtils';
import { telemetryConfig } from './TelemetryConfig';
import { getCachedUser } from '../../msalConfig';
import { API_STATUS, EVENTS } from '../../shared/constants/Telemetry.constants';

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: telemetryConfig.InstrumentationKey
  }
});
appInsights.loadAppInsights();

export namespace TelemetryService {
  // A flag to determine whether or not to send telemetry to dev console
  const LOG_TELEMETRY_TO_CONSOLE = process.env.NODE_ENV === 'development';

  /**
   * Log metric
   * Typically used to send regular reports of performance indicators.
   * @param metric            A string that identifies the metric.
   * @param customProperties  additional properties to send with the metric.
   */
  export function trackMetric(metric: IMetricTelemetry, customProperties?: { [key: string]: unknown }) {
    if (LOG_TELEMETRY_TO_CONSOLE) {
      // eslint-disable-next-line no-console
      console.log(`[Telemetry] Log Metric: ${metric}, ${flatProperties(customProperties)}`);
    } else {
      appInsights.trackMetric(metric, customProperties);
    }
  }

  /**
   * Page Track
   * Typically used to identify which pages in the application are being viewed.
   * @param event            To identify the prooperties of the page
   */
  export function trackPageView(event: IPageViewTelemetry) {
    if (LOG_TELEMETRY_TO_CONSOLE) {
      // eslint-disable-next-line no-console
      console.log(`[Telemetry] Log Page view: Name: ${event.name} url: ${event.uri}`);
    } else {
      appInsights.trackPageView(event);
    }
  }

  /**
   * Log an exception you have caught.
   * @param exception The exception to log.
   */
  export function trackException(exception: IExceptionTelemetry) {
    const sanitizedException = { ...exception };
    if (sanitizedException.properties?.Payload?.request?.headers?.Authorization) {
        sanitizedException.properties.Payload.request.headers.Authorization = 'REDACTED';
    }
    if (LOG_TELEMETRY_TO_CONSOLE) {
      // eslint-disable-next-line no-console
        console.log('[Telemetry] Log Exception: ', sanitizedException);
    } else {
        appInsights.trackException(sanitizedException);
    }
  }

  /**
   * Log a user action or other occurrence.
   * @param event             the TelemetryEvent to send
   * @param customProperties  Optional additional properties on the event
   */
  export function trackEvent(event: IEventTelemetry, customProperties?: { [key: string]: unknown }) {
    if (LOG_TELEMETRY_TO_CONSOLE) {
      // eslint-disable-next-line no-console
      console.log(`[Telemetry] Log Event: ${event}, ${flatProperties(customProperties)}`);
    } else {
      appInsights.trackEvent(event, customProperties);
    }
  }

  /**
   * Log a user action or other occurrence.
   * @param apiName           API Name
   * @param startTime         Start time
   * @param customProperties  Optional additional properties on the event
   */
  export function trackApiSuccess(apiName: string, startTime : DOMHighResTimeStamp, customProperties?: { [key: string]: unknown }) {
    trackEvent({ name: EVENTS.API_CALL } as IEventTelemetry, {
      ...customProperties,
      API: apiName,
      Result: API_STATUS.SUCCESS,
      Duration: Math.trunc(performance.now() - startTime),
      username: getCachedUser()?.username
    });
  }

  /**
   * Log a user action or other occurrence.
   * @param apiName           API Name
   * @param startTime         Start time
   * @param payload           Action payload from response
   * @param customProperties  Optional additional properties on the event
   */
  export function trackApiFailure(apiName: string, startTime : DOMHighResTimeStamp, payload: IHttpError, customProperties?: { [key: string]: unknown }) {
    trackException({
      exception: { message: API_STATUS.FAIL, name: apiName } as Error,
      properties: {
        ...customProperties,
        API: apiName,
        Payload: payload,
        Duration: Math.trunc(performance.now() - startTime),
        username: getCachedUser()?.username
      },
      severityLevel: SeverityLevel.Error
    } as IExceptionTelemetry);
  }

  /**
   * Log a diagnostic message.
   * @param trace             A diagnostic trace to log
   * @param customProperties  Optional additional properties of form map[string, string] - used to filter traces in the portal.Defaults to empty.
   */
  export function trackTrace(trace: ITraceTelemetry, customProperties?: { [key: string]: unknown }) {
    if (LOG_TELEMETRY_TO_CONSOLE) {
      // eslint-disable-next-line no-console
      console.log(`[Telemetry] Log Trace: ${trace} ${flatProperties(customProperties)}`);
    } else {
      appInsights.trackTrace(trace, customProperties);
    }
  }

  /**
   * to see data sent immediately
   */
  export function sendTelemetryNow() {
    appInsights.flush();
  }
}
