import { createReducer } from 'typesafe-actions';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { API_NAMES } from '../../../constants';
import { getSharePointSites, getSharePointSitesFail, getSharePointSitesSuccess } from '../../actions/sharePointSites/getSharePointSites';

export const getSharePointSitesReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(getSharePointSites, (state) => {
    return {
      ...state,
      getSharePointSitesState: {
        ...state.getSharePointSitesState,
        isLoading: true,
        loadingState: LoadingState.loading,
        startTime: performance.now()
      }
    };
  })
  .handleAction(getSharePointSitesSuccess, (state, action) => {
    TelemetryService.trackApiSuccess(API_NAMES.GET_SHAREPOINTSITES, state.getSharePointSitesState.startTime);
    return {
      ...state,
      getSharePointSitesState: {
        ...state.getSharePointSitesState,
        sharePointSitesData: action.payload,
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(getSharePointSitesFail, (state, action) => {
    TelemetryService.trackApiFailure(API_NAMES.GET_SHAREPOINTSITES, state.getSharePointSitesState.startTime, action.payload)
    return {
      ...state,
      getSharePointSitesState: {
        ...state.getSharePointSitesState,
        isLoading: false,
        loadingState: LoadingState.error,
        loadingError: action.payload
      }
    };
  });