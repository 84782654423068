import { connect } from 'react-redux';
import { IRootState } from '../../../shared/store/IRootState';
import { ListMyRequestStatusMappedProps, ListMyRequestStatusActions } from './ListMyRequestStatus.types';
import { getListMyRequestStatus } from '../../../shared/store/actions';
import ListMyRequestStatus from './ListMyRequestStatus';

// Reducer States
const mapStateToProps = (
  state: IRootState
  // _ownProps: ConnectedListMyRequestStatusProps
): ListMyRequestStatusMappedProps => {
  const { lstMyRequestStatusState } = state;
  return {
    lstMyRequestStatusState: lstMyRequestStatusState
  };
};

// Actions
const mapDispatchToProps = (): ListMyRequestStatusActions => {
  return {
    getListMyRequestStatus: () => getListMyRequestStatus()
  };
};

export const ConnectedListMyRequestStatus = connect(mapStateToProps, mapDispatchToProps())(ListMyRequestStatus);
