import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form, FormikHelpers } from 'formik';
import { css, MessageBar, MessageBarType, PrimaryButton, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import { IEventTelemetry } from '@microsoft/applicationinsights-common';
import { submitMailboxSSSRequest } from '../../../shared/store/actions';
import { MailboxSSSRequestFormModel } from './MailboxSSSRequestFormModel';
import { MailboxSSSRequestForm } from './Form/MailboxSSSRequestForm';
import { MailboxSSSRequestFormStyle } from './Form/MailboxSSSRequestForm.styles';
import {
  mailboxSSSRequestFormValueValidationSchema,
  initialMailboxSSSRequestFormValue,
  MailboxSSSRequestFormValues
} from './MailboxSSSRequestFormValues';
import { LoadingState } from '../../../shared/models/ILoading';
import { SuccessBanner } from '../../SuccessBanner';
import { getCachedUser } from '../../../msalConfig';
import { TelemetryService } from '../../TelemetryService/TelemetryService';
import { EVENTS, PAGES } from '../../../shared/constants/Telemetry.constants';

const { formId, formField } = MailboxSSSRequestFormModel;

export const MailboxSSSRequestForms = (props: any) => {
  const [acceptTerm, setAcceptTerm] = useState(false);
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const dispatch = useDispatch();
  const submitFormCallback = (values: MailboxSSSRequestFormValues) => dispatch(submitMailboxSSSRequest(values));

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  initialMailboxSSSRequestFormValue.requesterEmail = getCachedUser()?.username.toString();

  useEffect(() => {
    if (props.submitMailboxSSSRequestState.loadingState === LoadingState.loaded) 
      setShowSuccessPage(true);
  }, [props.submitMailboxSSSRequestState.loadingState]);

  const isDisabled = (): boolean => {
    return props.submitMailboxSSSRequestState.loadingState === LoadingState.loading;
  };

  const handleSubmit = (values: MailboxSSSRequestFormValues, actions: FormikHelpers<MailboxSSSRequestFormValues>) => {
    TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
      page: PAGES.MAILBOXSSS_REQUEST,
      actionName: "Submit",
      actionType: EVENTS.BUTTON_CLICK,
      username: getCachedUser()?.username
    });
    actions.setSubmitting(true);
    submitFormCallback(values);
  };

  const handleAcceptTermChange = () => {
    setAcceptTerm(!acceptTerm);
  };
  return showSuccessPage ? (
    <SuccessBanner requestName="Mailbox SSS" newRequest="/mailbox-request/create" />
  ) : (
  <>
    <div  style={{margin:'20px 20px 20px 20px'}}>
    <MessageBar messageBarType={MessageBarType.severeWarning} isMultiline={true}>
    <Text>{`In light of recent SFI changes, the GA approval frequency has been revised to once a week. We are diligently working across teams on process improvements to maximize efficiency. However, during this transitional phase, please anticipate a 5 working day SLA from the GA Approval team following the submission of your mailbox SSS request.`}</Text>
      </MessageBar>
    </div>
    <Formik
      initialValues={initialMailboxSSSRequestFormValue}
      validationSchema={mailboxSSSRequestFormValueValidationSchema}
      onSubmit={handleSubmit}
      validateOnChange={true}
      validateOnBlur={true}
    >
      <Form id={formId}>
        <MailboxSSSRequestForm
          formField={formField}
          formId={formId}
          acceptTerm={acceptTerm}
          handleAcceptTermChange={handleAcceptTermChange}
        />
        <div className={css(MailboxSSSRequestFormStyle.submit)}>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <PrimaryButton type="submit" disabled={isDisabled()}>
              {isDisabled() ? 'Submitting' : 'Submit'}
            </PrimaryButton>
            {isDisabled() && <Spinner size={SpinnerSize.medium} />}
          </Stack>
        </div>
      </Form>
    </Formik>
    </>
  );
};
