import { ICommandBarItemProps } from '@fluentui/react';
import { IEventTelemetry } from '@microsoft/applicationinsights-common';
import { TelemetryService } from '../../../components/TelemetryService/TelemetryService';
import { EVENTS, PAGES } from '../../../shared/constants/Telemetry.constants';
import { getCachedUser } from '../../../msalConfig';

// Getting initial CommandBar configured
export const getRequestStatusCreateMailSSSCommandBarItems = (refresh: () => void): ICommandBarItemProps[] => {
  return [
    {
      key: 'refresh',
      name: 'Refresh',
      iconProps: {
        iconName: 'Refresh'
      },
      ariaLabel: 'Refresh. Use left and right arrow keys to navigate',
      onClick: () => {
        TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
          page: PAGES.MAILBOXSSS_REQUEST_STATUS,
          actionName: "Refresh",
          actionType: EVENTS.BUTTON_CLICK,
          username: getCachedUser()?.username
        });
        refresh();
      }
    }
  ];
};
