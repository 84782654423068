import { createReducer } from 'typesafe-actions';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import { toastError } from '../../../utils/tools/toast';
import { getFeatureFlags, getFeatureFlagsSucceeded, getFeatureFlagsFailed } from '../../actions';
import { LoadingState } from '../../../models/ILoading';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { API_NAMES } from '../../../constants';

export const getFeatureFlagsReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(getFeatureFlags, (state) => {
    return {
      ...state,
      featureFlagsRequestState: {
        ...state.featureFlagsRequestState,
        features: {},
        isLoading: true,
        loadingState: LoadingState.loading,
        startTime: performance.now()
      }
    };
  })
  .handleAction(getFeatureFlagsSucceeded, (state, action) => {
    TelemetryService.trackApiSuccess(API_NAMES.GET_FEATURE_FLAGS, state.featureFlagsRequestState.startTime);
    return {
      ...state,
      featureFlagsRequestState: {
        ...state.featureFlagsRequestState,
        features: action.payload,
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(getFeatureFlagsFailed, (state, action) => {
    toastError('Failed to fetch feature flags', action.payload);
    TelemetryService.trackApiFailure(API_NAMES.GET_FEATURE_FLAGS, state.featureFlagsRequestState.startTime, action.payload)
    return {
      ...state,
      featureFlagsRequestState: {
        ...state.featureFlagsRequestState,
        isLoading: false,
        loadingState: LoadingState.error,
        loadingError: action.payload
      }
    }
  })
