import { createReducer } from 'typesafe-actions';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import { getSharePointDetail, getSharePointDetailFailed, getSharePointDetailSucceeded } from '../../actions';
import { initialSharePointDetail } from '../../../models/SharepointSites/ISharePointDetailRequests';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { API_NAMES } from '../../../constants';

export const getSharePointDetailReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(getSharePointDetail, (state) => {
    return {
      ...state,
      getSharePointDetailRequestState: {
        ...state.getSharePointDetailRequestState,
        data: initialSharePointDetail,
        isLoading: true,
        loadingState: LoadingState.loading,
        startTime: performance.now()
      }
    };
  })
  .handleAction(getSharePointDetailSucceeded, (state, action) => {
    TelemetryService.trackApiSuccess(API_NAMES.GET_SHAREPOINT_DETAILS, state.getSharePointDetailRequestState.startTime);
    return {
      ...state,
      getSharePointDetailRequestState: {
        ...state.getSharePointDetailRequestState,
        data: action.payload,
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(getSharePointDetailFailed, (state, action) => {
    TelemetryService.trackApiFailure(API_NAMES.GET_SHAREPOINT_DETAILS, state.getSharePointDetailRequestState.startTime, action.payload)
    return {
      ...state,
      getSharePointDetailRequestState: {
        ...state.getSharePointDetailRequestState,
        isLoading: false,
        loadingState: LoadingState.error,
        loadingError: action.payload
      }
    };
  });
