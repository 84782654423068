import { createReducer } from 'typesafe-actions';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import {
  getListMailboxSSSRequests,
  getListMailboxSSSRequestsFail,
  getListMailboxSSSRequestsSuccess
} from '../../actions';
import { toastError } from '../../../utils/tools/toast';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { API_NAMES } from '../../../constants';

export const listMailboxSSSRequestsReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(getListMailboxSSSRequests, (state) => {
    return {
      ...state,
      lstMailboxSSSRequestsState: {
        ...state.lstMailboxSSSRequestsState,
        isLoading: true,
        loadingState: LoadingState.loading,
        startTime: performance.now()
      }
    };
  })
  .handleAction(getListMailboxSSSRequestsSuccess, (state, action) => {
    TelemetryService.trackApiSuccess(API_NAMES.GET_LIST_MAILBOX_SSS_REQUESTS, state.lstMailboxSSSRequestsState.startTime);
    return {
      ...state,
      lstMailboxSSSRequestsState: {
        ...state.lstMailboxSSSRequestsState,
        lstMailboxSSSRequests: action.payload,
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(getListMailboxSSSRequestsFail, (state, action) => {
    toastError('Fail to get mailbox SSS', action.payload);
    TelemetryService.trackApiFailure(API_NAMES.GET_LIST_MAILBOX_SSS_REQUESTS, state.lstMailboxSSSRequestsState.startTime, action.payload)
    return {
      ...state,
      lstMailboxSSSRequestsState: {
        ...state.lstMailboxSSSRequestsState,
        isLoading: false,
        loadingState: LoadingState.error,
        loadingError: action.payload
      }
    };
  });
