import { createReducer } from 'typesafe-actions';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import { getListMyRequestStatus, getListMyRequestStatusFail, getListMyRequestStatusSuccess } from '../../actions';
import { toastError } from '../../../utils/tools/toast';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { API_NAMES } from '../../../constants';

export const listMyRequestStatusReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(getListMyRequestStatus, (state) => {
    return {
      ...state,
      lstMyRequestStatusState: {
        ...state.lstMyRequestStatusState,
        lstMyRequestStatus: [],
        isLoading: true,
        loadingState: LoadingState.loading,
        startTime: performance.now()
      }
    };
  })
  .handleAction(getListMyRequestStatusSuccess, (state, action) => {
    TelemetryService.trackApiSuccess(API_NAMES.GET_LIST_MY_REQUEST_STATUS, state.lstMyRequestStatusState.startTime);
    return {
      ...state,
      lstMyRequestStatusState: {
        ...state.lstMyRequestStatusState,
        lstMyRequestStatus: [...action.payload.lstMyRequestStatus],
        hasMore: action.payload.hasMore,
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(getListMyRequestStatusFail, (state, action) => {
    toastError('Fail to get list request status', action.payload);
    TelemetryService.trackApiFailure(API_NAMES.GET_LIST_MY_REQUEST_STATUS, state.lstMyRequestStatusState.startTime, action.payload)
    return {
      ...state,
      lstMyRequestStatusState: {
        ...state.lstMyRequestStatusState,
        isLoading: false,
        loadingState: LoadingState.error,
        loadingError: action.payload
      }
    };
  });
