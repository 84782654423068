import { createReducer } from 'typesafe-actions';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import { getRequestStatusMailSSS, getRequestStatusMailSSSFail, getRequestStatusMailSSSSuccess } from '../../actions';
import { toastError } from '../../../utils/tools/toast';
import { IRequestStatus } from '../../../models/myRequestStatus/IRequestStatus';
import { IMailboxSSSRequest } from '../../../models/MailboxSSS/IMailboxSSSRequest';
import { IRequestStatusAuditLog } from '../../../models/myRequestStatus/IRequestStatusAuditLog';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { API_NAMES } from '../../../constants';

export const requestStatusMailboxSSSReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(getRequestStatusMailSSS, (state) => {
    return {
      ...state,
      requestStatusMailboxSSSState: {
        ...state.requestStatusMailboxSSSState,
        requestStatus: {} as IRequestStatus,
        mailboxSSSRequest: {} as IMailboxSSSRequest,
        lstAuditLogs: [] as IRequestStatusAuditLog[],
        isLoading: true,
        loadingState: LoadingState.loading,
        startTime: performance.now()
      }
    };
  })
  .handleAction(getRequestStatusMailSSSSuccess, (state, action) => {
    let requestStatus = {} as IRequestStatus;
    let mailboxSSSRequest = {} as IMailboxSSSRequest;
    let lstAuditLogs = [] as IRequestStatusAuditLog[];
    if (action.payload !== null) {
      requestStatus = action.payload.requestStatus;
      mailboxSSSRequest = action.payload.mailboxSSSRequest;
      lstAuditLogs = action.payload.lstAuditLogs;
    }
    TelemetryService.trackApiSuccess(API_NAMES.GET_REQUEST_STATUS_MAIL_SSS, state.requestStatusMailboxSSSState.startTime);
    return {
      ...state,
      requestStatusMailboxSSSState: {
        ...state.requestStatusMailboxSSSState,
        requestStatus: requestStatus,
        lstAuditLogs: lstAuditLogs,
        mailboxSSSRequest: mailboxSSSRequest,
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(getRequestStatusMailSSSFail, (state, action) => {
    toastError('Fail to get request status of mailbox sss', action.payload);
    TelemetryService.trackApiFailure(API_NAMES.GET_REQUEST_STATUS_MAIL_SSS, state.requestStatusMailboxSSSState.startTime, action.payload)
    return {
      ...state,
      requestStatusMailboxSSSState: {
        ...state.requestStatusMailboxSSSState,
        isLoading: false,
        loadingState: LoadingState.error,
        loadingError: action.payload
      }
    };
  });
