import { createReducer } from 'typesafe-actions';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import { submitMailboxSSSRequest, submitMailboxSSSRequestFail, submitMailboxSSSRequestSuccess } from '../../actions';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { API_NAMES } from '../../../constants';

export const SubmitMailboxSSSRequestReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(submitMailboxSSSRequest, (state) => {
    return {
      ...state,
      submitMailboxSSSRequestState: {
        ...state.submitMailboxSSSRequestState,
        isLoading: true,
        loadingState: LoadingState.loading,
        startTime: performance.now()
      }
    };
  })
  .handleAction(submitMailboxSSSRequestSuccess, (state, action) => {
    TelemetryService.trackApiSuccess(API_NAMES.SUBMIT_MAILBOXSSS_REQUEST, state.submitMailboxSSSRequestState.startTime);
    return {
      ...state,
      submitMailboxSSSRequestState: {
        ...state.submitMailboxSSSRequestState,
        mailboxSSSRequest: action.payload,
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(submitMailboxSSSRequestFail, (state, action) => {
    TelemetryService.trackApiFailure(API_NAMES.SUBMIT_MAILBOXSSS_REQUEST, state.submitMailboxSSSRequestState.startTime, action.payload)
    return {
      ...state,
      submitMailboxSSSRequestState: {
        ...state.submitMailboxSSSRequestState,
        isLoading: false,
        loadingState: LoadingState.error,
        loadingError: action.payload
      }
    };
  });
