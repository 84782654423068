import { IEventTelemetry } from '@microsoft/applicationinsights-common';
import { IPBIExecutedPolicies } from '../../shared/models/PBIWorkspaces/IPBIExecutedPolicies';
import { EXECUTED_POLICIES_RESULT } from '../../shared/constants/enums';
import { ADMIN_DELETED_PBI_URL, PBI_API_STATE } from './PBIWorkspaces.constants';
import { IPBIWorkspace } from '../../shared/models/PBIWorkspaces/IPBIWorkspace';
import { getCachedUser } from '../../msalConfig';
import { TelemetryService } from '../../components/TelemetryService/TelemetryService';
import { EVENTS, PAGES } from '../../shared/constants/Telemetry.constants';

export const isCompliant = (executedPolicies: IPBIExecutedPolicies[] | undefined): boolean => {
  if (executedPolicies?.length === 0 || executedPolicies === undefined) return true;
  for (let i = 0; i < executedPolicies?.length; i += 1) {
    if (executedPolicies[i].status === EXECUTED_POLICIES_RESULT.FAIL) {
         return false;
    }
  }
  return true;
};

export const getActionUrl = (item: IPBIWorkspace): string => {
  if (item.complianceState === PBI_API_STATE.DELETED) {
    return ADMIN_DELETED_PBI_URL;
  }
  // TODO: This URL will be updated to PBI violations url once we get actual data.
  return `/shared-workspaces/sharepointsites/${item.id}/violations`;
};

export const onStatusClick = (id: string | undefined) => {
  TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
    page: PAGES.PBI_WORKSPACES,
    actionName: "Violation Policies",
    id: id,
    actionType: EVENTS.LINK_CLICK,
    username: getCachedUser()?.username
  });
};
