import React, { useEffect } from 'react'
import { Stack, PivotItem } from '@fluentui/react'
import { IComboBoxNumberOption, IPaginationProps, OverflowOption, Pagination } from '@coherence-design-system/pagination';
import { useDispatch, useSelector } from 'react-redux';
import { IEventTelemetry } from '@microsoft/applicationinsights-common';
import { EVENTS, GRID, SCREEN_SIZE } from '../../shared/constants'
import { MailboxSSSGAApprovalWrapperProps } from './MailboxSSSGAApproval.types';
import { getListMailboxSSSRequests } from '../../shared/store/actions';
import { IRootState } from '../../shared/store/IRootState';
import { TelemetryService } from '../../components/TelemetryService/TelemetryService';
import { getCachedUser } from '../../msalConfig';
import { PAGES } from '../../shared/constants/Telemetry.constants';

export const MailboxSSSGAApprovalPagination: React.FC<MailboxSSSGAApprovalWrapperProps> = (props) => {

    const [currentOverflowOption, setCurrentOverflowOption] = React.useState<OverflowOption | undefined>();
    const {currentPage, totalItemsPerPage, totalMailboxSSSRequestsCount, setCurrentPage, setTotalItemsPerPage, setTotalMailboxSSSRequestsCount} = props;

    const dispatch = useDispatch();

    const lstMailboxSSSRequestsState = useSelector(
      (state: IRootState) => state.lstMailboxSSSRequestsState
    );

    const handleOverflowClick: NonNullable<IPaginationProps['overflowButtonProps']>['onClick'] = (_event) => {
      const updateOverflow = currentOverflowOption ? undefined : 'overflowAndPageDropdown';
      setCurrentOverflowOption(updateOverflow);
    };

    const handlePivotClick = (
      pivot?: PivotItem | undefined,
      _ev?: React.MouseEvent<HTMLElement, MouseEvent> | undefined): void => {
      const currentNumber = parseInt((pivot && pivot.props.headerText) || '1', 10);
      setCurrentPage(currentNumber);
    };

    const handleOnItemsPerPageChange = (_event: React.FormEvent<IComboBoxNumberOption>, pageNumber: number): void => {
      TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
        page: PAGES.MAILBOXSSS_GA_APPROVAL,
        actionName: "Page Size Change",
        actionType: EVENTS.PAGE_SIZE_CHANGE,
        oldPageSize: totalItemsPerPage,
        newPageSize: pageNumber,
        username: getCachedUser()?.username
      });
      setCurrentPage(Math.ceil(((currentPage-1) * totalItemsPerPage + 1)/pageNumber));
      setTotalItemsPerPage(pageNumber);
    };

    const handleOnPageChange = (_event: React.MouseEvent<HTMLButtonElement>, selectedPage: number | undefined): void => {
      if (selectedPage !== undefined) {
        setCurrentPage(selectedPage);
      }
    };

    const paginationProps: IPaginationProps = {
      previousPage: {
        ariaLabel: 'previous page'
      },
      nextPage: {
        ariaLabel: 'next page'
      },
      overflowButtonProps: {
        onClick: handleOverflowClick
      },
      pageControls: {
        currentPage: currentPage,
        pages: Math.ceil(totalMailboxSSSRequestsCount / totalItemsPerPage),
        overflowOption: currentOverflowOption,
        onPivotClick: handlePivotClick,
        pageDropdownWidth: 60
      },
      onPageChange: handleOnPageChange,
      itemsPerPageProps: {
        currentPageSize: totalItemsPerPage,
        itemsPerPageList: GRID.MAILBOXSSS_GA_APPROVAL_PAGINATION.PAGE_SIZE_LIST,
        onItemsPerPageChange: handleOnItemsPerPageChange,
        ariaLabel: GRID.MAILBOXSSS_GA_APPROVAL_PAGINATION.ARIA_LABEL
      }
    };

      useEffect(() => {
        const urlParams=`top=${totalItemsPerPage}&skip=${(currentPage-1) * totalItemsPerPage}`;
        dispatch(getListMailboxSSSRequests(urlParams));
      }, [currentPage, totalItemsPerPage, dispatch]);

      useEffect(() => {
        setTotalMailboxSSSRequestsCount(lstMailboxSSSRequestsState.lstMailboxSSSRequests?.totalRecords);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [lstMailboxSSSRequestsState]);

  return (
    <>
    <Stack>
        <Stack horizontalAlign="center" tokens={{ padding: 10, childrenGap: 10 }}>
            <Stack
                horizontal
                horizontalAlign="center"
                {...(window.innerWidth > SCREEN_SIZE.MEDIUM ? { horizontal: true } : {})}
            >
                <Pagination {...paginationProps} />
            </Stack>
        </Stack>
    </Stack>
</>

  )
}

export default MailboxSSSGAApprovalPagination