import { createReducer } from 'typesafe-actions';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import { submitEnvironmentRequest, submitEnvironmentRequestFail, submitEnvironmentRequestSuccess } from '../../actions';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { API_NAMES } from '../../../constants';
import { toastError } from '../../../utils/tools/toast';

export const submitEnvironmentRequestReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(submitEnvironmentRequest, (state) => {
    return {
      ...state,
      submitEnvironmentRequestState: {
        ...state.submitEnvironmentRequestState,
        isLoading: true,
        loadingState: LoadingState.loading,
        startTime: performance.now()
      }
    };
  })
  .handleAction(submitEnvironmentRequestSuccess, (state, action) => {
    TelemetryService.trackApiSuccess(API_NAMES.SUBMIT_ENVIRONMENT_REQUEST, state.submitEnvironmentRequestState.startTime);
    return {
      ...state,
      submitEnvironmentRequestState: {
        ...state.submitEnvironmentRequestState,
        environmentRequest: action.payload,
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(submitEnvironmentRequestFail, (state, action) => {
    toastError('Failed to submit Environment Request', action.payload);
    TelemetryService.trackApiFailure(API_NAMES.SUBMIT_ENVIRONMENT_REQUEST, state.submitEnvironmentRequestState.startTime, action.payload)
    return {
      ...state,
      submitEnvironmentRequestState: {
        ...state.submitEnvironmentRequestState,
        isLoading: false,
        loadingState: LoadingState.error,
        loadingError: action.payload
      }
    };
  });
