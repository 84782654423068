import {
  ScrollablePane,
  Spinner,
  SpinnerSize,
  CommandBar,
  css,
  DetailsList,
  CheckboxVisibility,
  DetailsListLayoutMode,
  ProgressIndicator,
  TextField,
  Modal,
  PrimaryButton
} from '@fluentui/react';
import * as React from 'react';
import { IPageViewTelemetry } from '@microsoft/applicationinsights-common';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { MailboxSSSGAApprovalProps, MailboxSSSGAApprovalState } from './MailboxSSSGAApproval.types';
import { getSSSGAApprovalCommandBarItems } from './MailboxSSSGAApproval.commands';
import { getTableListMailboxSSSRequestsColumns } from './MailboxSSSGAApproval.columns';
import { isNullOrUndefined } from '../../shared/utils/tools/object';
import { CustomSelection } from '../../components';
import { IApprovalMailboxSSSRequest } from '../../shared/models/MailboxSSS/IApprovalMailboxSSSRequest';
import { IListApprvalMailboxSSSRequestsState } from '../../shared/models/MailboxSSS/IListApprvalMailboxSSSRequestsState';
import { ILoading, LoadingState } from '../../shared/models/ILoading';
import {
  MailboxSSSGAApprovalPageCommandBarStyle,
  MailboxSSSGAApprovalPageStyle,
  MailboxSSSGAApprovalPageContentStyles
} from './MailboxSSSGAApproval.styles';
import { _Styles } from '../../shared/styles/Page.styles';
import { renderEveryOtherRow } from '../../shared/utils/fabric/renderEveryOtherRow';
import MailboxSSSGAApprovalPagination from './MailboxSSSGAApprovalPagination';
import { TelemetryService } from '../../components/TelemetryService/TelemetryService';
import { PAGES } from '../../shared/constants/Telemetry.constants';

export class MailboxSSSGAApproval extends React.Component<MailboxSSSGAApprovalProps, MailboxSSSGAApprovalState> {
  private _selection: CustomSelection;

  constructor(props: MailboxSSSGAApprovalProps) {
    super(props);
    this.state = {
      isApproved: false,
      isOpenCommentsModal: false,
      comments: '',
      selectedCount: 0,
      currentPage: 1,
      totalItemsPerPage: 50,
      totalMailboxSSSRequestsCount: 0
    };

    if (isNullOrUndefined(this.props.listMailboxSSSRequestsState.lstMailboxSSSRequests)) {
      const urlParams=`top=${this.state.totalItemsPerPage}&skip=${(this.state.currentPage-1) * this.state.totalItemsPerPage}`;
      this.props.getListMailboxSSSRequests(urlParams);
    }

    this._selection = new CustomSelection({
      onSelectionChanged: () => {
        this.setState({
          selectedCount: this._selection.getSelectedCount()
        });
      }
    });
  }

  public componentDidMount() {
    TelemetryService.trackPageView({
      name: PAGES.MAILBOXSSS_GA_APPROVAL,
      uri: window.location.href,
    } as IPageViewTelemetry);
    this.refresh();
  }

  public componentDidUpdate(preProps: MailboxSSSGAApprovalProps, _preState: MailboxSSSGAApprovalState) {
    if (
      !isNullOrUndefined(preProps.listApprvalMailboxSSSRequestsState.curRequestEnsureSystemAdmin) &&
      preProps.listApprvalMailboxSSSRequestsState.curRequestEnsureSystemAdmin.isLoading === true &&
      this.props.listApprvalMailboxSSSRequestsState.curRequestEnsureSystemAdmin.isLoading === false
    ) {
      this.triggerBulkApproval(this.props.listApprvalMailboxSSSRequestsState);
    }

    if (
      !isNullOrUndefined(preProps.listApprvalMailboxSSSRequestsState.curRequestUserEmailApproval) &&
      preProps.listApprvalMailboxSSSRequestsState.curRequestUserEmailApproval.isLoading === true &&
      this.props.listApprvalMailboxSSSRequestsState.curRequestUserEmailApproval.isLoading === false
    ) {
      this.triggerBulkApproval(this.props.listApprvalMailboxSSSRequestsState);
    }
  }

  public render(): React.ReactNode {
    const commands = getSSSGAApprovalCommandBarItems(
      this.props.listApprvalMailboxSSSRequestsState.isLoading,
      !isNullOrUndefined(this.props.listMailboxSSSRequestsState) &&
        !isNullOrUndefined(this.props.listMailboxSSSRequestsState.lstMailboxSSSRequests) &&
        this.props.listMailboxSSSRequestsState.lstMailboxSSSRequests.values.length > 0,
      this.state.selectedCount,
      this.refresh,
      this.approveRequest
    );
    const columns = getTableListMailboxSSSRequestsColumns();
    return (
      <ScrollablePane
        className={this.props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}
      >
        <main
          data-automation-id="MailSSSGAApproval"
          className={css(MailboxSSSGAApprovalPageStyle.pageBackground)}
          tabIndex={-1}
        >
          <PageHeader pageTitle={'Mailbox SSS Global Admin Approval'} />
          <CommandBar
            styles={MailboxSSSGAApprovalPageCommandBarStyle}
            className={'MailSSSGAApproval-bar'}
            items={commands}
            ariaLabel={'Mailbox SSS Global Admin Approval Commands'}
          />
          {this.props.listMailboxSSSRequestsState.isLoading ||
          this.props.listApprvalMailboxSSSRequestsState.isLoading ? (
            <div className={'role-assignment-list'} data-is-scrollable="true">
              {this.props.listApprvalMailboxSSSRequestsState.isLoading ? (
                <>
                  <Spinner size={SpinnerSize.large} label={'Approving mailbox SSS ...'} />
                  Total: {this.props.listApprvalMailboxSSSRequestsState.lstApprovalMailboxSSSRequests.length}
                  <br />
                  Success: {this.props.listApprvalMailboxSSSRequestsState.countSuccess}
                  <br />
                  Fail: {this.props.listApprvalMailboxSSSRequestsState.countFail}
                  <br />
                  Current working on [{this.props.listApprvalMailboxSSSRequestsState.curRequest + 1}]
                  <br />
                  <a>
                    {
                      this.props.listApprvalMailboxSSSRequestsState.lstApprovalMailboxSSSRequests[
                        this.props.listApprvalMailboxSSSRequestsState.curRequest
                      ].crmOrganizationUrl
                    }
                    <br />
                    {
                      this.props.listApprvalMailboxSSSRequestsState.lstApprovalMailboxSSSRequests[
                        this.props.listApprvalMailboxSSSRequestsState.curRequest
                      ].mailboxEmail
                    }
                  </a>
                  <ProgressIndicator
                    percentComplete={
                      (this.props.listApprvalMailboxSSSRequestsState.countSuccess +
                        this.props.listApprvalMailboxSSSRequestsState.countFail) /
                      this.props.listApprvalMailboxSSSRequestsState.lstApprovalMailboxSSSRequests.length
                    }
                  />
                  {this.props.listApprvalMailboxSSSRequestsState.curRequestEnsureSystemAdmin.isLoading
                    ? 'Setup User as System Admin'
                    : null}
                  {this.props.listApprvalMailboxSSSRequestsState.curRequestUserEmailApproval.isLoading
                    ? 'Approve user email router access'
                    : null}
                </>
              ) : (
                <Spinner size={SpinnerSize.large} label={'Loading...'} />
              )}
            </div>
          ) : (
            <>
              {!isNullOrUndefined(this.props.listMailboxSSSRequestsState.lstMailboxSSSRequests) ? (
                // && this.props.listMailboxSSSRequestsState.lstMailboxSSSRequests.length > 0
                <div className={'role-assignment-list'} data-is-scrollable="true">
                  <Modal
                    titleAriaId={'Comments Modal'}
                    isOpen={this.state.isOpenCommentsModal}
                    onDismiss={this.hideCommentsModal}
                    isBlocking={false}
                    containerClassName={MailboxSSSGAApprovalPageContentStyles.container}
                  >
                    <PageHeader pageTitle={'Comments'} />
                    <TextField
                      required
                      multiline={true}
                      className={MailboxSSSGAApprovalPageContentStyles.input}
                      value={this.state.comments}
                      onChange={(
                        _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                        newValue: string | undefined
                      ) => {
                        this.setState({
                          comments: newValue === undefined ? '' : newValue
                        });
                      }}
                    />
                    <PrimaryButton
                      text={this.state.isApproved ? 'Approve' : 'Reject'}
                      className={MailboxSSSGAApprovalPageContentStyles.submit}
                      onClick={this.submitApproveRequest}
                      disabled={this.state.comments === ''}
                    />
                  </Modal>

                  <DetailsList
                    ariaLabelForGrid={'Mailbox SSS requests List'}
                    ariaLabelForSelectionColumn="Toggle mailbox SSS requests selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all requests"
                    isHeaderVisible={true}
                    checkboxVisibility={CheckboxVisibility.onHover}
                    items={Array.from(this.props.listMailboxSSSRequestsState.lstMailboxSSSRequests?.values)}
                    onRenderRow={renderEveryOtherRow}
                    columns={columns}
                    setKey="requestId"
                    selection={this._selection}
                    selectionPreservedOnEmptyClick={true}
                    layoutMode={DetailsListLayoutMode.justified}
                    // onRenderItemColumn={_renderItemColumn}
                  />
                </div>
              ) : null}
            </>
          )}
          {!this.props.listApprvalMailboxSSSRequestsState.isLoading &&
          <MailboxSSSGAApprovalPagination
            currentPage={this.state.currentPage}
            totalItemsPerPage={this.state.totalItemsPerPage}
            totalMailboxSSSRequestsCount={this.state.totalMailboxSSSRequestsCount}
            setCurrentPage={this.setCurrentPage}
            setTotalItemsPerPage={this.setTotalItemsPerPage}
            setTotalMailboxSSSRequestsCount={this.setTotalMailboxSSSRequestsCount}
          />}
        </main>
      </ScrollablePane>
    );
  }

  private refresh = () => {
    this.setState({
      isApproved: false
    });
    let urlParams=`top=${this.state.totalItemsPerPage}&skip=${(this.state.currentPage-1) * this.state.totalItemsPerPage}`;
    this.props.getListMailboxSSSRequests(urlParams);
  };

  private setCurrentPage = (currentPage: number) => {
    this.setState({
      currentPage: currentPage
    });
  };

  private setTotalItemsPerPage = (totalItemsPerPage: number) => {
    this.setState({
      totalItemsPerPage: totalItemsPerPage
    });
  };

  private setTotalMailboxSSSRequestsCount = (totalMailboxSSSRequestsCount: number) => {
    this.setState({
      totalMailboxSSSRequestsCount: totalMailboxSSSRequestsCount
    });
  };

  private approveRequest = (isApproved: boolean) => {
    if (this._selection.count <= 0 || this.props.listApprvalMailboxSSSRequestsState.isLoading) {
      return;
    }

    this.setState({
      isOpenCommentsModal: true,
      isApproved: isApproved,
      comments: ''
    });
  };

  private hideCommentsModal = () => {
    this.setState({
      isOpenCommentsModal: false,
      isApproved: false,
      comments: ''
    });
  };

  private submitApproveRequest = () => {
    this.setState({
      isOpenCommentsModal: false
    });

    const listApprvalMailboxSSSRequestsState: IListApprvalMailboxSSSRequestsState = {} as IListApprvalMailboxSSSRequestsState;
    listApprvalMailboxSSSRequestsState.isApproved = this.state.isApproved;
    listApprvalMailboxSSSRequestsState.countSuccess = 0;
    listApprvalMailboxSSSRequestsState.countFail = 0;
    listApprvalMailboxSSSRequestsState.curRequest = 0;
    listApprvalMailboxSSSRequestsState.lstApprovalMailboxSSSRequests = this._selection.getSelection() as IApprovalMailboxSSSRequest[];
    listApprvalMailboxSSSRequestsState.curRequestEnsureSystemAdmin = {} as ILoading;
    listApprvalMailboxSSSRequestsState.curRequestUserEmailApproval = {} as ILoading;
    this.props.initListApprvalMailboxSSSRequestsState(listApprvalMailboxSSSRequestsState);
    this.triggerBulkApproval(listApprvalMailboxSSSRequestsState);
  };

  private triggerBulkApproval = (state: IListApprvalMailboxSSSRequestsState) => {
    let curRequest = state.curRequest;
    if (
      !isNullOrUndefined(state.curRequestUserEmailApproval) &&
      (state.curRequestUserEmailApproval.loadingState === LoadingState.loaded ||
        state.curRequestUserEmailApproval.loadingState === LoadingState.error)
    ) {
      curRequest += 1;
    } else if (
      !isNullOrUndefined(state.curRequestEnsureSystemAdmin) &&
      state.curRequestEnsureSystemAdmin.loadingState === LoadingState.loaded
    ) {
      // Ensure System Admin Success
      this.props.userEmailApproval(
        this.state.isApproved,
        this.state.comments,
        curRequest,
        state.lstApprovalMailboxSSSRequests[curRequest]
      );
      return;
    } else if (
      !isNullOrUndefined(state.curRequestEnsureSystemAdmin) &&
      state.curRequestEnsureSystemAdmin.loadingState === LoadingState.error
    ) {
      // Ensure System Admin Fail go to next request
      curRequest += 1;
    }

    if (curRequest > state.lstApprovalMailboxSSSRequests.length - 1) {
      // To Set Complete
      this.setState({
        isApproved: false
      });
      let urlParams=`top=${this.state.totalItemsPerPage}&skip=${(this.state.currentPage-1) * this.state.totalItemsPerPage}`;
      this.props.completeListApprvalMailboxSSSRequestsState();
      this.props.getListMailboxSSSRequests(urlParams);
      this._selection = new CustomSelection({});
      return;
    }

    // to trigger ensure systemadmin
    if (this.state.isApproved === true) {
      this.props.ensureSystemAdmin(curRequest, state.lstApprovalMailboxSSSRequests[curRequest]);
      return;
    }

    this.props.userEmailApproval(
      this.state.isApproved,
      this.state.comments,
      curRequest,
      state.lstApprovalMailboxSSSRequests[curRequest]
    );
  };
}
