import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ScrollablePane, ShimmeredDetailsList, Stack, css, IColumn, DefaultButton } from '@fluentui/react';
import { IPageViewTelemetry } from '@microsoft/applicationinsights-common';
import { _Styles } from '../../shared/styles/Page.styles';
import { Assets } from '../../shared/styles/Assets.styles';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { IPBIWorkspace } from '../../shared/models/PBIWorkspaces/IPBIWorkspace';
import { GetPBIWorkspaces } from '../../shared/services/PBIWorkspaces/GetPBIWorkspaces';
import { ErrorBanner } from '../../components/ErrorBanner';
import { PBI_CONST, PBI_API_STATE, COLUMNS } from './PBIWorkspaces.constants';
import { PBIWorkspaceStyle, iconStyles } from './PBIWorkspaces.styles';
import { isCompliant, getActionUrl, onStatusClick } from './PBIWorkspaces.helper';
import { TelemetryService } from '../../components/TelemetryService/TelemetryService';
import { PAGES } from '../../shared/constants/Telemetry.constants';

export function PBIWorkspaces(props: { isNavCollapsed: any }) {
  const [pbiWorkspacesList, setPbiWorkspacesList] = useState<IPBIWorkspace[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const history = useHistory();
  const [columns, setColumns] = useState(COLUMNS);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const workspacesList = await GetPBIWorkspaces();
        setPbiWorkspacesList(workspacesList);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  const onRenderItemColumn = (item: IPBIWorkspace, index: number, column: IColumn) => {
    const fieldContent = item[column.fieldName as keyof IPBIWorkspace] as string;
    switch (column.key) {
      case 'violationCount':
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        return (
          <div title={PBI_CONST.ApiStatus[item.complianceState]} className={css(iconStyles.statusWrapper)}>
            <div className={css(iconStyles.circle, iconStyles[PBI_CONST.StatusStyle[item.complianceState]])} />
            <span>{PBI_CONST.ApiStatus[item.complianceState]}</span>
          </div>
        );

      case 'actionRequired':
        return !isCompliant(item.executedPolicies) ? (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <DefaultButton
            aria-label="Press to remediate violation"
            className={css(PBIWorkspaceStyle.actionButton)}
            text={PBI_CONST.ActionLabel[item.complianceState]}
            onClick={() => {
              onStatusClick(item.id);
              const url = getActionUrl(item);
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              if (item.complianceState === PBI_API_STATE.DELETED) {
                window.open(url);
              } else {
                history.push(url);
              }
            }}
          />
        ) : (
          <div />
        );
      case 'quarantineDate':
        return !isCompliant(item.executedPolicies) ? <span>{fieldContent}</span> : <div />;
      default:
        return <span>{fieldContent}</span>;
    }
  };
  
  useEffect(()=>{
    TelemetryService.trackPageView({
      name: PAGES.PBI_WORKSPACES,
      uri: window.location.href,
    } as IPageViewTelemetry);
  },[]);

  if (isError)
    return (
      <>
        <ScrollablePane
          className={props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}
        >
          <br />
          <ErrorBanner />
        </ScrollablePane>
      </>
    );

  return (
    <ScrollablePane
      className={props.isNavCollapsed ? _Styles.scrollablePaneCollapsed : _Styles.scrollablePaneExpand}
      aria-label="PowerBI workspaces List"
      role="region"
    >
      <main data-automation-id="PBIWorkspaces" className={css(Assets.pageBackground)} tabIndex={-1}>
        <div id="no-horizontal-scroll" className={css(Assets.noHorizontalScroll)}>
          <PageHeader pageTitle={'PowerBI Workspaces'} />
          <Stack>
            <div className={css(Assets.listWrapper)}>
              <ShimmeredDetailsList
                columns={columns}
                ariaLabelForGrid="PBI Workspaces"
                items={pbiWorkspacesList || []}
                enableShimmer={isLoading}
                onRenderItemColumn={onRenderItemColumn}
              />
            </div>
          </Stack>
        </div>
      </main>
    </ScrollablePane>
  );
}
