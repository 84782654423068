import { ICommandBarItemProps } from '@fluentui/react';
import { IEventTelemetry } from '@microsoft/applicationinsights-common';
import { TelemetryService } from '../../components/TelemetryService/TelemetryService';
import { EVENTS, PAGES } from '../../shared/constants/Telemetry.constants';
import { getCachedUser } from '../../msalConfig';

// Getting initial CommandBar configured
export const getSSSGAApprovalCommandBarItems = (
  isApprovalLoading: boolean,
  isListRequestsExisted: boolean,
  selectionCount: number,
  refresh: () => void,
  approveRequest: (isApproved: boolean) => void
): ICommandBarItemProps[] => {
  if (isApprovalLoading) {
    return [];
  }
  if (isListRequestsExisted) {
    return [
      {
        key: 'refresh',
        name: 'Refresh',
        iconProps: {
          iconName: 'Refresh'
        },
        ariaLabel: 'Refresh. Use left and right arrow keys to navigate',
        onClick: () => {
          TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
            page: PAGES.MAILBOXSSS_GA_APPROVAL,
            actionName: "Refresh",
            actionType: EVENTS.BUTTON_CLICK,
            username: getCachedUser()?.username
          });
          refresh();
        }
      },
      {
        key: 'aprove',
        name: 'Approve',
        iconProps: {
          iconName: 'Accept'
        },
        ariaLabel: 'Approve. Use left and right arrow keys to navigate',
        disabled: selectionCount <= 0,
        onClick: () => {
          TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
            page: PAGES.MAILBOXSSS_GA_APPROVAL,
            actionName: "Approve",
            actionType: EVENTS.BUTTON_CLICK,
            username: getCachedUser()?.username
          });
          approveRequest(true);
        }
      },
      {
        key: 'reject',
        name: 'Reject',
        iconProps: {
          iconName: 'Cancel'
        },
        ariaLabel: 'Reject. Use left and right arrow keys to navigate',
        disabled: selectionCount <= 0,
        onClick: () => {
          TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
            page: PAGES.MAILBOXSSS_GA_APPROVAL,
            actionName: "Reject",
            actionType: EVENTS.BUTTON_CLICK,
            username: getCachedUser()?.username
          });
          approveRequest(false);
        }
      }
    ];
  }
  return [
    {
      key: 'refresh',
      name: 'Refresh',
      iconProps: {
        iconName: 'Refresh'
      },
      ariaLabel: 'Refresh. Use left and right arrow keys to navigate',
      onClick: () => refresh()
    }
  ];
};
