import { createReducer } from "typesafe-actions";
import { IRootState } from "../../IRootState";
import { RootActions } from "../../RootActions";
import { initialRootState } from "../../initialRootState";
import { getSharePointPolicies, getSharePointPoliciesFail, getSharePointPoliciesSuccess } from "../../actions/policies/getSharepointPolicies";
import { LoadingState } from "../../../models/ILoading";
import { objectUtils } from "../../../utils";
import { TelemetryService } from "../../../../components/TelemetryService/TelemetryService";
import { API_NAMES } from "../../../constants";

export const GetSharepointPoliciesReducer = createReducer<IRootState, RootActions>(initialRootState)
.handleAction(getSharePointPolicies, (state) => {
    return {
        ...state,
        sharePointPoliciesRequestState: {
            ...state.sharePointPoliciesRequestState,
            isLoading: true,
            loadingState: LoadingState.loading,
            startTime: performance.now()
        }
    };
})
.handleAction(getSharePointPoliciesSuccess, (state, action) => {
    TelemetryService.trackApiSuccess(API_NAMES.GET_SHAREPOINT_POLICIES, state.sharePointPoliciesRequestState.startTime);
    return {
        ...state,
        sharePointPoliciesRequestState: {
            ...state.sharePointPoliciesRequestState,
            data: objectUtils.convertToObjByKeyAsId(action.payload),
            isLoading: false,
            loadingState: LoadingState.loaded
        }
    };
})
.handleAction(getSharePointPoliciesFail, (state, action) => {
    TelemetryService.trackApiFailure(API_NAMES.GET_SHAREPOINT_POLICIES, state.sharePointPoliciesRequestState.startTime, action.payload)
    return {
        ...state,
        sharePointPoliciesRequestState: {
            ...state.sharePointPoliciesRequestState,
            isLoading: false,
            loadingState: LoadingState.error,
            loadingError: action.payload
        }
    };
})