/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import {
  css,
  DefaultButton,
  DirectionalHint,
  Label,
  Link,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  FocusZone,
  FocusTrapCallout,
  FocusZoneTabbableElements
} from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';
import { provideFluentDesignSystem, fluentAccordion, fluentAccordionItem } from '@fluentui/web-components';
import moment from 'moment';
import { useBoolean } from '@uifabric/react-hooks';
import { IEventTelemetry } from '@microsoft/applicationinsights-common';
import { setEnvironmentAttestation } from '../../../shared/store/actions';
import { IEnvironmentAttestationFormProps } from './EnvironmentAttestation.model';
import {
  EnvironmentAttestationSubmitValidation,
  EnvironmentAttestationSaveValidation
} from './EnvironmentAttestation.validation';
import { FormTextField } from '../Items/FormTextField';
import { FormDropdown } from '../Items/FormDropdown';
import { FormCheckbox } from '../Items/FormCheckbox';
import { FormServiceTreePicker, ServiceTreeSearchType } from '../Items/FormServiceTreePicker';
import { IEnvironmentAttestation, IUser } from '../../../shared/models/Environment/IEnvironmentAttestationRequest';
import { LoadingState } from '../../../shared/models/ILoading';
import { ButtonIconWithTooltip } from '../../InfoIcon/ButtonIconWithTooltip';
import {
  formStyles,
  labelStyles,
  messageBarStyles,
  stackStyles,
  horizontalGapCdStackTokens,
  horizontalGapIdStackTokens,
  horizontalGapNameStackTokens,
  stackTokens,
  footerStyles
} from './EnvironmentAttestation.styles';
import { FormFieldWithTooltip } from '../FormFieldWithTooltip';
import { FormPeoplePickerV1 } from '../Items/FormPeoplePickerV1';
import { DataClassificationOptions } from '../../../shared/constants/enums';
import { TelemetryService } from '../../TelemetryService/TelemetryService';
import { objectUtils } from '../../../shared/utils';
import { detectErrorSectionWise } from './EnvironmentAttestation.helper';
import { checkUnsavedChanges } from '../../../shared/utils/web/UnsavedChanges';
import { IRootState } from '../../../shared/store/IRootState';
import { getCachedUser } from '../../../msalConfig';
import { EVENTS, PAGES } from '../../../shared/constants/Telemetry.constants';

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
provideFluentDesignSystem().register(fluentAccordion(), fluentAccordionItem());

export const EnvironmentAttestationForm: React.FC<IEnvironmentAttestationFormProps> = (props) => {
  const dispatch = useDispatch();
  const { onSubmitCallback, attestationDetails, updateViolationState, operationType } = props;
  const [showErrorMessageBar, setShowErrorMessageBar] = React.useState<boolean>(false);
  const [formValidationSchema, setFormValidationSchema] = useState(EnvironmentAttestationSaveValidation);
  const hideErrorMessageBar = useCallback(() => setShowErrorMessageBar(false), []);
  const [isOwnerCalloutVisible, { toggle: toggleIsOwnerCalloutVisible }] = useBoolean(false);
  const [submitType, setSubmitType] = useState('');
  const [isFormValuesChanged, setFormValuesChanged] = useState(false);
  const [attestationValues, setAttestationValues] = useState<IEnvironmentAttestation | null>(null);
  const [isEnvSave, setIsEnvSave] = useState(false);
  const setEnvironmentAttestationRequestState = useSelector(
    (state: IRootState) => state.setEnvironmentAttestationRequestState
  );

  useEffect(() => {
    const _checkUnsavedChanges = checkUnsavedChanges(isFormValuesChanged);
    return () => {
      _checkUnsavedChanges();
    };
  }, [isFormValuesChanged]);

  useEffect(() => {
    if (
      setEnvironmentAttestationRequestState?.loadingState === LoadingState.loaded &&
      (submitType === 'submit' || submitType === 'save')
    ) {
      onSubmitCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setEnvironmentAttestationRequestState]);

  useEffect(() => {
    if (setEnvironmentAttestationRequestState.loadingState === LoadingState.error) {
      setShowErrorMessageBar(true);
    }
    if (
      setEnvironmentAttestationRequestState.loadingState === LoadingState.loaded &&
      attestationValues !== null &&
      isEnvSave
    ) {
      updateViolationState();
      setIsEnvSave(false);
    }
  }, [setEnvironmentAttestationRequestState.loadingState, attestationValues, updateViolationState, isEnvSave]);

  function onFormSubmit(values: IEnvironmentAttestation) {
    if (props.environmentId !== undefined) {
      const attestationValues = { ...values, id: props.environmentId };
      attestationValues.actionType = submitType;
      setAttestationValues(attestationValues);
      dispatch(setEnvironmentAttestation(attestationValues, operationType, 'Environment', submitType));
      setIsEnvSave(true);
    }
  }

  function formatOwners(owners: IUser[]): string | JSX.Element {
    if (owners === null || owners === undefined || owners?.length === 0) {
      return 'No Information available';
    }
    if (owners?.length === 1) {
      return `${owners[0].displayName}`;
    }
    if (owners?.length === 2) {
      return `${owners[0].displayName}, ${owners[1].displayName}`;
    }

    return (
      <div>
        {owners[0].displayName}, {owners[1].displayName} and{' '}
        <Link
          id={'otherOwnersLink'}
          onClick={toggleIsOwnerCalloutVisible}
          aria-label={`${owners[0].displayName}, ${owners[1].displayName} and  ${owners.length - 2} others`}
        >
          {owners.length - 2} others
        </Link>
        {isOwnerCalloutVisible && (
          <FocusTrapCallout
            role="dialog"
            gapSpace={0}
            target={'#otherOwnersLink'}
            onDismiss={toggleIsOwnerCalloutVisible}
            directionalHint={DirectionalHint.rightCenter}
          >
            <Link style={{ padding: '5px', maxHeight: 300, overflowY: 'auto', color: 'black' }}>
              {[...owners].splice(2).map((item) => (
                <p>{item.displayName}</p>
              ))}
            </Link>
            <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
              <Stack horizontal horizontalAlign="end">
                <PrimaryButton style={{ margin: '10px' }} onClick={toggleIsOwnerCalloutVisible}>
                  Dismiss
                </PrimaryButton>
              </Stack>
            </FocusZone>
          </FocusTrapCallout>
        )}
      </div>
    );
  }

  const newInitialValues = attestationDetails;
  if (newInitialValues.description == null) newInitialValues.description = '';
  if (newInitialValues.justification == null) newInitialValues.justification = '';
  return (
    <div className={css(formStyles.attestationContainer)}>
      {setEnvironmentAttestationRequestState.loadingState === LoadingState.error && showErrorMessageBar && (
        <div style={{ position: 'sticky' }}>
          <MessageBar messageBarType={MessageBarType.error} onDismiss={hideErrorMessageBar}>
            {setEnvironmentAttestationRequestState.loadingError?.response?.isValid === false ? (
              <div>
                <ul>
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
                  {setEnvironmentAttestationRequestState.loadingError?.response?.errorMessage.map(
                    (item, index: number) => (
                      <li key={Date.now().toString().concat('-', index.toString())}>{item}</li>
                    )
                  )}
                </ul>
              </div>
            ) : (
              `Something Went Wrong. Please try again`
            )}
          </MessageBar>
        </div>
      )}

      <Formik
        enableReinitialize={false}
        initialValues={attestationDetails}
        validationSchema={formValidationSchema}
        onSubmit={onFormSubmit}
      >
        {({ errors }) => (
          <Form>
            <fluent-accordion role="region" aria-label="Accordion">
              <fluent-accordion-item role="presentation" expanded={true}>
                <span
                  slot="heading"
                  className={css(formStyles.heading)}
                  role="heading"
                  aria-label="Section 1 - Environment Information"
                  aria-level="2"
                >
                  Section 1 - Environment Information
                  {detectErrorSectionWise(errors).isInformationError && (
                    <Icon
                      iconName="error"
                      aria-label="Error in environment information section"
                      className={css(formStyles.errorIcon)}
                    />
                  )}
                </span>
                <div className={css(formStyles.staticFieldContent)}>
                  <Stack horizontal disableShrink styles={stackStyles} tokens={horizontalGapNameStackTokens}>
                    <Label>Name:</Label>
                    <Label styles={labelStyles}>
                      {newInitialValues.environmentName ? newInitialValues.environmentName : ''}
                    </Label>
                  </Stack>
                  <Stack horizontal disableShrink styles={stackStyles} tokens={horizontalGapCdStackTokens}>
                    <Label>Created Date:</Label>
                    <Label styles={labelStyles}>
                      {newInitialValues.createdTime ? moment(newInitialValues.createdTime).format('YYYY-MM-DD') : ' '}
                    </Label>
                  </Stack>
                  <Stack horizontal disableShrink styles={stackStyles} tokens={horizontalGapIdStackTokens}>
                    <Label>Id:</Label>
                    <Label styles={labelStyles}> {newInitialValues.id ? newInitialValues.id : ''}</Label>
                  </Stack>
                </div>
                <div className={css(formStyles.fieldContent)}>
                  <FormFieldWithTooltip
                    as={FormTextField}
                    label="Description"
                    name="description"
                    tooltip="Provide the description of the environment. Description should be in between 5 to 500 characters"
                    required={true}
                    multiline
                    autoAdjustHeight
                    aria-label="Description"
                    aria-describedby="Description-tooltip"
                    aria-required="true"
                    aria-readonly="false"
                    aria-disabled="false"
                    onChange={setFormValuesChanged(true)}
                  />
                </div>
                <FormFieldWithTooltip
                  as={FormTextField}
                  label="Business Justification"
                  tooltip="Provide Business justification for the environment, how this environment is being used and the impact. Environments are created for business purpose, and they are not meant for demo’s, POCs. Business justification should be in between 5 to 500 characters."
                  name="justification"
                  required={true}
                  multiline
                  autoAdjustHeight
                  aria-label="Business Justification"
                  aria-describedby="Business Justification-tooltip"
                  aria-required="true"
                  aria-readonly="false"
                  aria-disabled="false"
                  onChange={setFormValuesChanged(true)}
                />
                <FormFieldWithTooltip
                  as={FormTextField}
                  tooltip="Target audience could be set of role/personas or specific orgs using this environment. Number of characters should be in between 5 to 50"
                  label="Target Audience"
                  name="audience"
                  required={true}
                  aria-label="Target Audience"
                  aria-required="true"
                  aria-readonly="false"
                  aria-disabled="false"
                  aria-describedby="Target Audience-tooltip"
                  onChange={setFormValuesChanged(true)}
                />
              </fluent-accordion-item>
              <fluent-accordion-item role="presentation">
                <span
                  slot="heading"
                  className={css(formStyles.heading)}
                  aria-label="Section 2 - Ownership"
                  role="heading"
                  aria-level="2"
                >
                  Section 2 - Ownership
                  {detectErrorSectionWise(errors).isOwnershipError && (
                    <Icon
                      iconName="error"
                      aria-label="Error in ownership section"
                      aria-disabled="false"
                      className={css(formStyles.errorIcon)}
                    />
                  )}
                </span>
                <div className={css(formStyles.staticFieldContent)}>
                  <Stack horizontal disableShrink styles={stackStyles} tokens={horizontalGapCdStackTokens}>
                    <Label>Owners:</Label>
                    <Label styles={labelStyles}>{formatOwners(newInitialValues?.owners)}</Label>
                  </Stack>
                </div>
                <div className={css(formStyles.formColSmall)}>
                  <FormFieldWithTooltip
                    as={FormServiceTreePicker}
                    label="Owning Team"
                    name="owningTeam"
                    aria-label="Owning Team"
                    aria-required="true"
                    aria-readonly="false"
                    aria-disabled="false"
                    required={true}
                    limitResults={5}
                    searchType={ServiceTreeSearchType.Organization}
                    suggestionsHeader="Suggested Service Tree Objects (Division > Organization)"
                    onChange={setFormValuesChanged(true)}
                    tooltip= "Select the organization the engineering team owning the environment belongs to. You may search by Division or Organization name. Note, this field will not provide anyone with access to the environment."
                  />
                </div>
                <Field
                  as={FormPeoplePickerV1}
                  label="Business Sponsor"
                  name="businessSponsor"
                  required={false}
                  limitResults={5}
                  onChange={setFormValuesChanged(true)}
                />
              </fluent-accordion-item>
              <fluent-accordion-item role="presentation">
                <span slot="heading" aria-label="Section 3 - Compliance" className={css(formStyles.heading)}>
                  Section 3 - Compliance
                  {detectErrorSectionWise(errors).isComplianceError && (
                    <Icon
                      iconName="error"
                      aria-label="Error in compliance section"
                      className={css(formStyles.errorIcon)}
                    />
                  )}
                </span>
                <Field
                  as={FormDropdown}
                  label="Data Classification"
                  name="dataClassification"
                  required={true}
                  value={newInitialValues.dataClassification?.toString()}
                  options={DataClassificationOptions}
                  onChange={setFormValuesChanged(true)}
                  aria-required="true"
                  aria-disabled="false"
                />
                <div>
                  <div
                    id="checkbox-group"
                    aria-disabled="false"
                    style={{ fontSize: '14px', fontWeight: 600, padding: '5px 0px' }}
                  >
                    Regulatory Scope (select all that apply)
                  </div>
                  <div role="group" aria-disabled="false" aria-labelledby="checkbox-group">
                    <div className={css(formStyles.checkboxWrapper)}>
                      <Field
                        as={FormCheckbox}
                        label="HIPAA Data"
                        name="isHIPAA"
                        required={true}
                        aria-disabled="false"
                        aria-labelledby="hipaa-checkbox-label"
                        onChange={setFormValuesChanged(true)}
                      />
                    </div>
                    <div className={css(formStyles.checkboxWrapper)}>
                      <Field
                        as={FormCheckbox}
                        label="Personal (GDPR) Data"
                        name="isGDPR"
                        required={true}
                        aria-disabled="false"
                        aria-labelledby="gdpr-checkbox-label"
                        onChange={setFormValuesChanged(true)}
                      />
                      <ButtonIconWithTooltip
                        aria-disabled="false"
                        tooltipContent="For information on privacy policies of Microsoft, GDPR and personal data, visit aka.ms/NGP"
                      />
                    </div>
                    <div className={css(formStyles.checkboxWrapper)}>
                      <Field
                        as={FormCheckbox}
                        label="Tented or Sensitive Information"
                        name="isTented"
                        required={true}
                        aria-disabled="false"
                        aria-labelledby="tented-or-sensitive-checkbox-label"
                        onChange={setFormValuesChanged(true)}
                      />
                    </div>
                    <div className={css(formStyles.checkboxWrapper)}>
                      <Field
                        as={FormCheckbox}
                        label="SOX Applications"
                        name="isSOX"
                        required={true}
                        aria-disabled="false"
                        aria-labelledby="sox-applications-checkbox-label"
                        onChange={setFormValuesChanged(true)}
                      />
                    </div>
                  </div>
                </div>
                <Field
                  as={FormServiceTreePicker}
                  label="ServiceTree ID"
                  name="serviceTreeId"
                  required={false}
                  limitResults={5}
                  searchType={ServiceTreeSearchType.Service}
                  suggestionsHeader="Suggested Service Tree Services"
                  onChange={setFormValuesChanged(true)}
                />
              </fluent-accordion-item>
            </fluent-accordion>
            <div style={{ marginTop: '20px', marginBottom: '10px' }}>
              <Stack>
                <div style={{ float: 'left', marginBottom: '15px' }}>
                  <Stack>
                    <Field
                      as={FormCheckbox}
                      label="I hereby confirm that data provided above is true to my knowledge."
                      name="isAttestationConfirmed"
                      onChange={setFormValuesChanged(true)}
                    />
                  </Stack>
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
                  {!objectUtils.isEmpty(errors) && (
                    <MessageBar messageBarType={MessageBarType.error} isMultiline={false} styles={messageBarStyles}>
                      {detectErrorSectionWise(errors).message}
                    </MessageBar>
                  )}
                </div>
                <div style={{ float: 'right' }}>
                  {setEnvironmentAttestationRequestState.loadingState === LoadingState.loading && (
                    <Spinner size={SpinnerSize.medium} label={'Saving...'} labelPosition="left" />
                  )}
                  {!setEnvironmentAttestationRequestState.isLoading && (
                    <div className={css(formStyles.buttonBar)}>
                      <Stack horizontal tokens={stackTokens} styles={footerStyles}>
                        {!newInitialValues.actionType && (
                          <DefaultButton
                            type="submit"
                            onClick={() => {
                              TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
                                page: props.operationType === 'Metadata Update' ? PAGES.ENVIRONMENT_EDIT_METADATA : PAGES.ENVIRONMENT_ATTESTATION,
                                actionName: "Save Draft",
                                actionType: EVENTS.BUTTON_CLICK,
                                username: getCachedUser()?.username
                              });
                              setFormValidationSchema(EnvironmentAttestationSaveValidation);
                              setSubmitType('save');
                            }}
                          >
                            Save Draft
                          </DefaultButton>
                        )}
                        {!(getCachedUser()?.username.startsWith('v-') && attestationDetails.assetType !== "PPEnvDeveloper") && (
                          <PrimaryButton
                            disabled={setEnvironmentAttestationRequestState.isLoading}
                            type="submit"
                            onClick={() => {
                              setFormValidationSchema(EnvironmentAttestationSubmitValidation);
                              setSubmitType('submit');
                              TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
                                page: props.operationType === 'Metadata Update' ? PAGES.ENVIRONMENT_EDIT_METADATA : PAGES.ENVIRONMENT_ATTESTATION,
                                actionName: "Submit",
                                actionType: EVENTS.BUTTON_CLICK,
                                username: getCachedUser()?.username
                              });
                            }}
                          >
                            Submit
                          </PrimaryButton>
                        )}
                      </Stack>
                    </div>
                  )}
                </div>
              </Stack>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
