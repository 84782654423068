import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { httpGetV1 } from '../RestApi';
import { AUTH_SCOPE } from '../../constants';
import { getPBIWorkspacesUrl } from '../Api';
import { IPBIWorkspace } from '../../models/PBIWorkspaces/IPBIWorkspace';

export async function GetPBIWorkspaces() : Promise<IPBIWorkspace[]> {  
    const url = getPBIWorkspacesUrl();
    try {
      const request = {
        url: url,
        scope: AUTH_SCOPE.GOVERNANCE
      };

      return from(httpGetV1(request)).pipe(
        map((res) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
           return res.response.value as IPBIWorkspace[];
        })).toPromise();
    }catch {
        throw new Error('Error while fetching data.');
    }
  }
export default [GetPBIWorkspaces];
