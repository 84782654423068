import { IColumn, Link } from '@fluentui/react';
import * as React from 'react';
import { IMyRequestStatus } from '../../../shared/models/myRequestStatus/IMyRequestStatus';
import { dateTimeToLocalString } from '../../../shared/utils/tools/string';

export interface ITableListMyRequestStatusColumn extends IColumn {
  fieldName: keyof IMyRequestStatus;
  onRender?(item: IMyRequestStatus): React.ReactNode;
}

export const getTableListMyRequestStatusColumns = (clickLink: (requestId: string, requestType: string) => void) => {
  const columns: ITableListMyRequestStatusColumn[] = [
    {
      key: 'requestId',
      name: 'RequestId',
      fieldName: 'requestId',
      headerClassName: 'tbl-header',
      minWidth: 80,
      maxWidth: 240,
      isResizable: true,
      onRender: (item: IMyRequestStatus) => {
        return <Link role="link" onClick={() => clickLink(item.requestId, item.requestType)}>{item.requestId}</Link>;
      }
    }
  ];
  columns.push({
    key: 'requestType',
    name: 'Request Type',
    fieldName: 'requestType',
    headerClassName: 'tbl-header',
    minWidth: 80,
    maxWidth: 120,
    isResizable: true
  });
  columns.push({
    key: 'generalStatus',
    name: 'Status',
    fieldName: 'generalStatus',
    headerClassName: 'tbl-header',
    minWidth: 80,
    maxWidth: 120,
    isResizable: true
  });
  columns.push({
    key: 'createdOn',
    name: 'Created On (Local Time)',
    fieldName: 'createdOn',
    headerClassName: 'tbl-header',
    minWidth: 80,
    maxWidth: 300,
    isResizable: true,
    onRender: (item: IMyRequestStatus) => {
      return <div>{dateTimeToLocalString(item.createdOn)}</div>;
    }
  });

  return columns;
};
