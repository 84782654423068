import { createReducer } from 'typesafe-actions';
import { LoadingState } from '../../../models/ILoading';
import { IRootState } from '../../IRootState';
import { initialRootState } from '../../initialRootState';
import { RootActions } from '../../RootActions';
import {
  getRequestStatusEnvironment,
  getRequestStatusEnvironmentFail,
  getRequestStatusEnvironmentSuccess
} from '../../actions';
import { toastError } from '../../../utils/tools/toast';
import { IRequestStatus } from '../../../models/myRequestStatus/IRequestStatus';
import { IRequestStatusAuditLog } from '../../../models/myRequestStatus/IRequestStatusAuditLog';
import { IEnvironmentRequest } from '../../../../components/Forms/EnvironmentRequestForm/EnvironmentRequestFormValue';
import { TelemetryService } from '../../../../components/TelemetryService/TelemetryService';
import { API_NAMES } from '../../../constants';

export const requestStatusEnvironmentReducer = createReducer<IRootState, RootActions>(initialRootState)
  .handleAction(getRequestStatusEnvironment, (state) => {
    return {
      ...state,
      requestStatusEnvironmentState: {
        ...state.requestStatusEnvironmentState,
        requestStatus: {} as IRequestStatus,
        environmentRequest: {} as IEnvironmentRequest,
        lstAuditLogs: [] as IRequestStatusAuditLog[],
        isLoading: true,
        loadingState: LoadingState.loading,
        startTime: performance.now()
      }
    };
  })
  .handleAction(getRequestStatusEnvironmentSuccess, (state, action) => {
    let requestStatus = {} as IRequestStatus;
    let environmentRequest = {} as IEnvironmentRequest;
    let lstAuditLogs = [] as IRequestStatusAuditLog[];
    if (action.payload !== null) {
      requestStatus = action.payload.requestStatus;
      environmentRequest = action.payload.environmentRequest;
      lstAuditLogs = action.payload.lstAuditLogs;
    }
    TelemetryService.trackApiSuccess(API_NAMES.GET_REQUEST_STATUS_ENVIRONMENT, state.requestStatusEnvironmentState.startTime);
    return {
      ...state,
      requestStatusEnvironmentState: {
        ...state.requestStatusEnvironmentState,
        requestStatus: requestStatus,
        lstAuditLogs: lstAuditLogs,
        environmentRequest: environmentRequest,
        isLoading: false,
        loadingState: LoadingState.loaded
      }
    };
  })
  .handleAction(getRequestStatusEnvironmentFail, (state, action) => {
    toastError('Fail to get request status of mailbox sss', action.payload);
    TelemetryService.trackApiFailure(API_NAMES.GET_REQUEST_STATUS_ENVIRONMENT, state.requestStatusEnvironmentState.startTime, action.payload)
    return {
      ...state,
      requestStatusEnvironmentState: {
        ...state.requestStatusEnvironmentState,
        isLoading: false,
        loadingState: LoadingState.error,
        loadingError: action.payload
      }
    };
  });
