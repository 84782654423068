import { ICommandBarItemProps } from '@fluentui/react';
import { IEventTelemetry } from '@microsoft/applicationinsights-common';
import { TelemetryService } from '../../../components/TelemetryService/TelemetryService';
import { EVENTS, PAGES } from '../../../shared/constants/Telemetry.constants';
import { getCachedUser } from '../../../msalConfig';

// Getting initial CommandBar configured
export const getListMyRequestStatusCommandBarItems = (refresh: () => void): ICommandBarItemProps[] => {
  return [
    {
      key: 'newItem',
      text: 'New',
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Add' },
      subMenuProps: {
        items: [
          {
            key: 'mailboxSSS',
            text: 'Mailbox SSS',
            iconProps: { iconName: 'Mail' },
            ariaLabel: 'Mailbox SSS. Use left and right arrow keys to Create Mailbox SSS',
            onClick: () => {
              TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
                  page: PAGES.REQUEST_STATUS,
                  actionName: "New Mailbox SSS",
                  actionType: EVENTS.LINK_CLICK,
                  username: getCachedUser()?.username
              });
              location.href = '/mailbox-request/create';
            }
          },
          {
            key: 'environment',
            text: 'Environment',
            iconProps: { iconName: 'AddOnlineMeeting' },
            ariaLabel: 'Environment. Use left and right arrow keys to Create Environment',
            onClick: () => {
              TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
                  page: PAGES.REQUEST_STATUS,
                  actionName: "New Environment",
                  actionType: EVENTS.LINK_CLICK,
                  username: getCachedUser()?.username
              });
              location.href = '/environment-request';
            }
          }
        ]
      }
    },
    {
      key: 'refresh',
      name: 'Refresh',
      iconProps: {
        iconName: 'Refresh'
      },
      ariaLabel: 'Refresh. Use left and right arrow keys to navigate',
      onClick: () => {
        TelemetryService.trackEvent({ name: EVENTS.USER_ACTION } as IEventTelemetry, {
            page: PAGES.REQUEST_STATUS,
            actionName: "Refresh",
            actionType: EVENTS.LINK_CLICK,
            username: getCachedUser()?.username
        });
        refresh();
      }
    }
  ];
};
